import { TFunction } from 'i18next';
import { EMPTY_VALUE } from 'shared/constants';
import { formatNumber } from 'shared/utils';

export default (
  touchpointValue: string | number | null,
  t: TFunction<'translation', undefined, 'translation'>
) => {
  if (touchpointValue === null) return EMPTY_VALUE;
  if (typeof touchpointValue === 'boolean') {
    return touchpointValue ? '✓' : '×';
  }
  if (typeof touchpointValue === 'number') {
    return formatNumber(touchpointValue);
  }
  return t(`calculator.${touchpointValue}`);
};
