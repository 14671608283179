import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TotalBudget from 'shared/components/TotalBudget/TotalBudget';
import { Countries } from 'shared/enums';
import { Budget } from 'store/totalBudgetStore';

const firstRowProps = {
  fontWeight: '900',
  fontSize: '0.9rem',
  color: 'textDark.main',
} as const;

const secondRowProps = {
  fontSize: '0.9rem',
  fontWeight: '500',
} as const;

const thirdRowProps = {
  fontSize: '0.9rem',
  fontWeight: '500',
} as const;

function StyledTotalBudget({
  budget: { total, mustHave, optional },
  country,
}: {
  country: Countries;
  budget: Budget;
}) {
  const { t } = useTranslation();
  return (
    <Grid
      container
      item
      xs={12}
      style={{ flexBasis: '25%', padding: '1rem 1.5rem' }}
    >
      <Grid item xs={12} sx={{ textAlignLast: 'left' }}>
        <Typography color="textDark.main" fontSize="1rem" fontWeight={700}>
          {t(`countries.${country}`)}:
        </Typography>
      </Grid>

      <TotalBudget
        firstRowProps={firstRowProps}
        secondRowProps={secondRowProps}
        thirdRowProps={thirdRowProps}
        total={total}
        mustHave={mustHave}
        optional={optional}
        countryCurrency={country === Countries.POLAND ? 'PLN' : 'EUR'}
      />
    </Grid>
  );
}

export default StyledTotalBudget;
