import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { MediaCalculatorUser, AccessRoles } from 'shared/auth';

interface AuthContextType {
  userRoles: AccessRoles[];
}

const AuthContext = createContext<AuthContextType>({ userRoles: [] });

export const isAllowed = (allowedRoles: AccessRoles[], roles: AccessRoles[]) =>
  roles.some((role) => allowedRoles.includes(role));

export function AuthProvider({ children }: React.PropsWithChildren) {
  const { user, isLoading } = useAuth0<MediaCalculatorUser>();
  const [userRoles, setUserRoles] = useState<AccessRoles[]>([]);

  useEffect(() => {
    if (user && !isLoading) {
      setUserRoles(user['media-calculator/roles'] || []);
    }
  }, [user, isLoading]);

  const value = useMemo(() => ({ userRoles }), [userRoles]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuthContext = () => useContext(AuthContext);
