import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import { Countries } from 'shared/enums';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslation } from 'react-i18next';
import TotalBudget, { TotalBudgetProps } from '../TotalBudget/TotalBudget';

interface CountryAccordionProps {
  expanded: string | false;
  onToggleExpanded: (
    panel: string
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  country: Countries;
  budgetTotal: TotalBudgetProps['budget'];
  children: React.ReactNode;
}

function CountryAccordion({
  expanded,
  onToggleExpanded,
  country,
  budgetTotal,
  children,
}: CountryAccordionProps) {
  const { t } = useTranslation();
  return (
    <Accordion
      expanded={expanded === country}
      onChange={onToggleExpanded(country)}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        aria-controls={`${country}-content`}
        id={`${country}-header`}
        sx={{
          borderBottom: expanded === country ? 'none' : '3px solid white',
          boxShadow:
            expanded === country
              ? '0px -2px 0px rgba(0, 0, 0, 0.02), 0px 4px 16px rgba(0, 0, 0, 0.15)'
              : 'none',
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          height="fit-content"
          gap="1.5rem"
        >
          <SvgIcon color="textDark" sx={{ fontSize: '2rem' }}>
            {expanded === country ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </SvgIcon>
          <Typography
            fontWeight="700"
            textTransform="uppercase"
            color="textDark"
          >
            {t(`countries.${country}`)}
          </Typography>
        </Stack>
        <TotalBudget budget={budgetTotal} />
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

export default CountryAccordion;
