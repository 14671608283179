import { AppBar as MuiAppBar, Box, styled } from '@mui/material';
import Logo from './components/Logo/Logo';
import Navigation from './components/Navigation/Navigation';

const StyledAppBar = styled(MuiAppBar)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: '0.5rem 2rem',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
}));

const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexGrow: 1,
});

function AppBar() {
  return (
    <StyledAppBar position="sticky">
      <StyledBox>
        <Logo />
        <Navigation />
      </StyledBox>
    </StyledAppBar>
  );
}

export default AppBar;
