import { Modal, ModalProps, styled } from '@mui/material';
import Box from '@mui/material/Box';

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  padding: '24px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  borderRadius: '15px',
  backgroundColor: theme.palette.common.white,
}));

function ModalWindow({ children, ...props }: ModalProps) {
  return (
    <Modal {...props}>
      <StyledBox>{children}</StyledBox>
    </Modal>
  );
}

export default ModalWindow;
