import { footerRow } from './constants';
import { FooterRow, Metric, UseFooterDataProps } from './types';

export const calculateMetrics = (
  calculateFn: (metric: Metric) => number
): FooterRow => {
  return (Object.keys(footerRow) as Metric[]).reduce(
    (totals: FooterRow, metric) => {
      const newTotals = { ...totals };
      newTotals[metric] = calculateFn(metric);
      return newTotals;
    },
    footerRow
  );
};

export const calculateOptional = (
  key: string,
  rows: UseFooterDataProps['rows'],
  optionalIds: UseFooterDataProps['optionalIds']
) =>
  rows.reduce(
    (acc, item) =>
      !item.isMustHave && optionalIds[item.id] ? acc + item[key] : acc,
    0
  );

export const calculateMustHaveTotal = (
  key: string,
  rows: UseFooterDataProps['rows'],
  mustHaveIds: Record<number, boolean>
) =>
  rows.reduce(
    (acc, item) =>
      Object.keys(mustHaveIds).includes(String(item.id))
        ? acc + item[key]
        : acc,
    0
  );
