import { styled, TableCell, TableFooter } from '@mui/material';

export const StyledFooter = styled(TableFooter)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: theme.palette.common.white,

  width: '100%',
  boxShadow: '0px -4px 8px rgba(82,95,129,0.1)',
}));

export const FooterLabelCol = styled(TableCell)({
  padding: '0 4rem 0 1rem',
  borderBottom: 'none',
  width: 'fit-content',
});

export const FooterTableCell = styled(TableCell)({
  padding: '0 1rem',
  borderBottom: 'none',
  verticalAlign: 'top',
});

export const FooterDivider = styled(TableCell)(({ theme }) => ({
  padding: 0,
  borderBottom: `1px solid ${theme.palette.border.main}`,
}));
