import { ApiResponse } from 'api/types';
import { fetchData, postData } from 'api/utils';

export const generateReport = async (body: {
  transactionId: string;
  type: 'digital-fair-value' | 'tv-fair-value';
}) => {
  return postData(`/pdf-documents/generate`, body);
};

export const getCheckDocStatus = async (transactionId: string) => {
  return fetchData<ApiResponse<any>>(
    `/pdf-documents/check-doc-status?transactionId=${transactionId}`
  );
};
