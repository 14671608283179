/* eslint-disable camelcase */
import { create } from 'zustand';

export type FullDataAccessChannel = {
  channel: string;
  linkToAccountDashboard: string;
  accessOwner: string;
  digitalConsultant: string;
};

export type WithoutFullDataAccessChannel = {
  benchmark: string;
  channel: string;
  impressions: number;
  cpm: string;
  cost: number;
  numberOfDays: number;
  cpc: number;
  clicks: number;
};

export interface TvFairValueFormData {
  divisions: { id: number; title: string }[];
  brands: { id: number; title: string }[];
  partner: string;
  step: number;
  acceptDedAndInvoiceIssuedGranularly: string | null;
  acceptReceivingFullSalesFromThePartner: string | null;
  dashboardExcel: string;
  period1_campaignPeriod: string;
  period1_campaignTG: string;
  period1_suggestedLorealTG: string;
  period1_copyLength: number;
  period1_lorealCommLength: number;
  period1_grpNatural: number;

  period2_campaignPeriod: string;
  period2_campaignTG?: string;
  period2_suggestedLorealTG?: string;
  period2_copyLength?: number;
  period2_lorealCommLength?: number;
  period2_grpNatural?: number;

  campaignValue: number;
  shouldIncludeSecondPeriod: boolean;
  currentStep: number;
}

interface StoreTvFairValue {
  formData: TvFairValueFormData;
  updateForm: (newForm?: TvFairValueFormData) => void;
  resetForm: () => void;
}

export const useTvFairValueFormStore = create<StoreTvFairValue>((set) => ({
  formData: {} as TvFairValueFormData,
  updateForm: (newForm?: TvFairValueFormData) =>
    set((state) => ({
      ...state,
      formData: newForm || ({} as TvFairValueFormData),
    })),
  resetForm: () => {
    set((state) => ({
      ...state,
      formData: {
        divisions: [],
        brands: [],
        partner: '',
        step: 0,
        currentStep: 0,
        acceptDedAndInvoiceIssuedGranularly: null,
        acceptReceivingFullSalesFromThePartner: null,
        dashboardExcel: '',
        period1_campaignPeriod: '',
        period1_campaignTG: '',
        period1_suggestedLorealTG: '',
        period1_copyLength: 0,
        period1_lorealCommLength: 0,
        period1_grpNatural: 0,

        period2_campaignPeriod: '',
        period2_campaignTG: '',
        period2_suggestedLorealTG: '',
        period2_copyLength: 0,
        period2_lorealCommLength: 0,
        period2_grpNatural: 0,

        campaignValue: 0,
        shouldIncludeSecondPeriod: false,
      } as TvFairValueFormData,
    }));
  },
}));
