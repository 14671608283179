import { Countries } from 'shared/enums';
import { create } from 'zustand';

export interface Budget {
  mustHave: number | null;
  optional: number | null;
  total: number | null;
}

export interface StoreTotalBudget {
  [Countries.POLAND]: Budget;
  [Countries.LITHUANIA]: Budget;
  [Countries.LATVIA]: Budget;
  [Countries.ESTONIA]: Budget;
  resetTotalBudget: (key: Countries) => void;
  updateBudget: (key: Countries, newBudget: Budget) => void;
}

const INITIAL_BUDGET = {
  mustHave: 0,
  optional: 0,
  total: 0,
};

export const useTotalBudgetStore = create<StoreTotalBudget>((set) => ({
  [Countries.POLAND]: INITIAL_BUDGET,
  [Countries.LITHUANIA]: INITIAL_BUDGET,
  [Countries.LATVIA]: INITIAL_BUDGET,
  [Countries.ESTONIA]: INITIAL_BUDGET,
  resetTotalBudget: (country: Countries) =>
    set((state) => ({
      ...state,
      [country]: {
        mustHave: 0,
        optional: 0,
        total: 0,
      },
    })),
  updateBudget: (country: Countries, newBudget: Budget) =>
    set((state) => ({
      ...state,
      [country]: { ...state[country], ...newBudget },
    })),
}));
