import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { isAllowed, useAuthContext } from 'context/AuthContext';
import { AccessRoles } from 'shared/auth';
import { styled } from '@mui/material';

const Wrapper = styled('div')({
  display: 'flex',
  flexGrow: 1,
});
interface ProtectedRouteProps {
  allowedRoles: AccessRoles[];
}

export function ProtectedRoute({
  allowedRoles,
  children,
}: PropsWithChildren<ProtectedRouteProps>) {
  const { userRoles } = useAuthContext();

  if (!userRoles) {
    return null;
  }

  if (!isAllowed(allowedRoles, userRoles)) {
    return <Navigate to="/" replace />;
  }

  return <Wrapper>{children}</Wrapper>;
}

export default ProtectedRoute;
