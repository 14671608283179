import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const useValidationSchema = () => {
  const { t } = useTranslation();
  const valueCannotBeGreaterThanCopyLength = t(
    'validation.valueCannotBeGreaterThan',
    { field: 'CopyLength' }
  );

  return yup.object().shape({
    step: yup.number().optional(),
    divisions: yup.array().min(1, 'Please select at least one division'),
    brands: yup.array().min(1, 'Please select at least one brand'),
    partner: yup.string().required('Partner is required'),
    acceptDedAndInvoiceIssuedGranularly: yup.boolean().when('step', (value) => {
      if (value[0] === 0) {
        return yup.boolean().nullable();
      }
      return yup.boolean().required('You have to choose whether you agree');
    }),
    acceptReceivingFullSalesFromThePartner: yup
      .boolean()
      .when('step', (value) => {
        if (value[0] <= 1) {
          return yup.boolean().nullable();
        }
        return yup.boolean().required('You have to choose whether you agree');
      }),
    dashboardExcel: yup
      .string()
      .nullable()
      .when(
        ['step', 'acceptReceivingFullSalesFromThePartner'],
        ([step, acceptReceivingFullSalesFromThePartner], schema) => {
          return step[0] <= 1 ||
            acceptReceivingFullSalesFromThePartner === 'false' ||
            !acceptReceivingFullSalesFromThePartner
            ? schema // Allow null if step is less than or equal to 2
            : schema.required('Dashboard Excel is required');
        }
      ),
    shouldIncludeSecondPeriod: yup.boolean().when('step', (step, schema) => {
      return step[0] <= 2
        ? schema.nullable().notRequired()
        : schema.required('Should include second period is required');
    }),
    period1_campaignPeriod: yup.string().when('step', (step, schema) => {
      return step[0] > 2 ? schema.required() : schema.nullable().notRequired();
    }),
    period1_campaignTG: yup.string().when('step', (step, schema) => {
      return step[0] > 2 ? schema.required() : schema.nullable().notRequired();
    }),
    period1_suggestedLorealTG: yup.string().when('step', (step, schema) => {
      return step[0] > 2 ? schema.required() : schema.nullable().notRequired();
    }),
    period1_copyLength: yup.number().when('step', (step, schema) => {
      return step[0] > 2
        ? schema
            .transform((v) => (Number.isNaN(v) ? undefined : v))
            .required('Copy length is required')
            .positive('Copy length must be a positive number')
        : schema.nullable().notRequired();
    }),
    period1_lorealCommLength: yup.number().when('step', (step, schema) => {
      return step[0] > 2
        ? schema
            .transform((v) => (Number.isNaN(v) ? undefined : v))
            .moreThan(0)
            .max(
              yup.ref('period1_copyLength'),
              'Value cannot be greater than copy length'
            )
            .required()
        : schema.nullable();
    }),
    period1_grpNatural: yup.number().when('step', (step, schema) => {
      return step[0] > 2
        ? schema
            .transform((v) => (Number.isNaN(v) ? undefined : v))
            .moreThan(-1)
            .required()
        : schema.nullable();
    }),

    period2_campaignPeriod: yup
      .string()
      .when(
        ['shouldIncludeSecondPeriod', 'step'],
        ([shouldIncludeSecondPeriod, step], schema) => {
          return shouldIncludeSecondPeriod && step > 2
            ? schema.required()
            : schema.nullable().notRequired();
        }
      ),
    period2_campaignTG: yup
      .string()
      .when(
        ['shouldIncludeSecondPeriod', 'step'],
        ([shouldIncludeSecondPeriod, step], schema) => {
          return shouldIncludeSecondPeriod && step > 2
            ? schema.required()
            : schema.nullable().notRequired();
        }
      ),
    period2_suggestedLorealTG: yup
      .string()
      .when(
        ['shouldIncludeSecondPeriod', 'step'],
        ([shouldIncludeSecondPeriod, step], schema) => {
          return shouldIncludeSecondPeriod && step > 2
            ? schema.required()
            : schema.nullable().notRequired();
        }
      ),
    period2_copyLength: yup
      .number()
      .when(
        ['shouldIncludeSecondPeriod', 'step'],
        ([shouldIncludeSecondPeriod, step], schema) => {
          return shouldIncludeSecondPeriod && step > 2
            ? schema
                .transform((v) => (Number.isNaN(v) ? undefined : v))
                .required('Copy length is required')
                .positive('Copy length must be a positive number')
            : schema.nullable().notRequired();
        }
      ),
    period2_lorealCommLength: yup
      .number()
      .when(
        ['shouldIncludeSecondPeriod', 'step'],
        ([shouldIncludeSecondPeriod, step], schema) => {
          return shouldIncludeSecondPeriod && step > 2
            ? schema
                .transform((v) => (Number.isNaN(v) ? undefined : v))
                .moreThan(0)
                .max(
                  yup.ref('period2_copyLength'),
                  'Value cannot be greater than copy length'
                )
                .required()
            : schema.nullable();
        }
      ),
    period2_grpNatural: yup
      .number()
      .when(
        ['shouldIncludeSecondPeriod', 'step'],
        ([shouldIncludeSecondPeriod, step], schema) => {
          return shouldIncludeSecondPeriod && step > 2
            ? schema
                .transform((v) => (Number.isNaN(v) ? undefined : v))
                .moreThan(-1)
                .required()
            : schema.nullable();
        }
      ),

    campaignValue: yup.number().required(),
  });
};
