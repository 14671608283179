import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useTheme,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

export type GenerateReportDialogProps = {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  text?: string;
};

function ResetStepperFormDialog({
  open,
  onClose,
  onSuccess,
  text,
}: GenerateReportDialogProps) {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: { borderRadius: '10px', padding: '2rem', width: '500px' },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{
          verticalAlign: 'center',
          display: 'flex',
          color: theme.palette.error.main,
        }}
      >
        <WarningIcon
          style={{
            height: '40px',
            width: '40px',
            verticalAlign: 'middle',
            marginRight: '2rem',
          }}
          color="error"
        />
        <p style={{ margin: 0, alignSelf: 'self-end' }}>WARNING</p>
      </DialogTitle>

      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <p>
            {text ||
              'Are you sure you want to clear the form? This will take you to the beginning of the form and you will lose all the inserted data.'}
          </p>
          <p>Proceed?</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ gap: '1rem' }}>
        <Button onClick={onClose} variant="text">
          No
        </Button>
        <Button
          onClick={() => {
            onSuccess();
            onClose();
          }}
          variant="contained"
          color="primary"
          size="large"
          style={{ width: '100px' }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ResetStepperFormDialog;
