/* eslint-disable react/no-unescaped-entities */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  useTheme,
} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownIcon from '@mui/icons-material/ThumbDownOutlined';
import CloseIcon from '@mui/icons-material/Close';

export type GenerateReportDialogProps = {
  open: boolean;
  onClose: () => void;
  onGeneratePdf: () => void;
  onDownload: () => void;
  step: 'accept' | 'reject' | 'loading' | 'done';
  rejectParameters: Array<string>;
  rejectText: string | React.ReactNode;
};

function GenerateReportDialog({
  open,
  onClose,
  onGeneratePdf,
  onDownload,
  step,
  rejectParameters,
  rejectText,
}: GenerateReportDialogProps) {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { borderRadius: '10px', padding: '2rem' } }}
    >
      {step === 'accept' && (
        <DialogTitle
          id="alert-dialog-title"
          style={{
            verticalAlign: 'center',
            color: theme.palette.success.main,
          }}
        >
          <ThumbUpIcon
            style={{
              height: '40px',
              width: '40px',
              verticalAlign: 'middle',
              paddingBottom: '0.4rem',
              marginRight: '2rem',
            }}
            color="success"
          />
          ACCEPT!
        </DialogTitle>
      )}
      {step === 'reject' && (
        <DialogTitle
          id="alert-dialog-title"
          style={{
            verticalAlign: 'center',
            color: theme.palette.error.main,
          }}
        >
          <ThumbDownIcon
            style={{
              height: '40px',
              width: '40px',
              verticalAlign: 'middle',
              marginRight: '2rem',
            }}
            color="error"
          />
          REJECT!
          <IconButton
            aria-label="close"
            onClick={onClose}
            style={{
              position: 'absolute',
              right: '2rem',
              top: '1rem',
              marginRight: '-16px', // Adjust margin for visual alignment
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      )}
      {step === 'done' && (
        <DialogTitle
          id="alert-dialog-title"
          style={{
            verticalAlign: 'center',
            color: theme.palette.success.main,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={onClose}
            style={{
              position: 'absolute',
              right: '2rem',
              top: '1rem',
              marginRight: '-16px', // Adjust margin for visual alignment
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      )}
      {(step === 'accept' || step === 'reject') && <Divider />}
      <DialogContent>
        {step === 'accept' && (
          <DialogContentText id="alert-dialog-description">
            The supplier data falls into internal L'Oreal norms.
            <br />
            You can proceed with the campaign purchase.
          </DialogContentText>
        )}
        {step === 'done' && (
          <DialogContentText id="alert-dialog-description">
            You can download your document now.
          </DialogContentText>
        )}
        {step === 'loading' && (
          <DialogContentText
            id="alert-dialog-description"
            style={{
              textAlign: 'center',
            }}
          >
            <CircularProgress style={{ width: '1.5rem', height: '1.5rem' }} />
            <br />
            We are generating your document.
          </DialogContentText>
        )}
        {step === 'reject' && !rejectText && (
          <DialogContentText id="alert-dialog-description">
            The data you supplied does not fulfill L'Oreal internal
            <br />
            requirements.
            <br />
            Check the parameters for the following channels:
            <br />
            <ul>
              {rejectParameters.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </DialogContentText>
        )}
        {step === 'reject' && rejectText && (
          <DialogContentText id="alert-dialog-description">
            {rejectText}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {step === 'accept' && (
          <Button
            onClick={onGeneratePdf}
            style={{
              backgroundColor: '#1976d2', // Blue color
              color: 'white',
              borderRadius: '20px',
              margin: '0 auto',
              padding: '0.5rem 2rem',
              fontWeight: 'bold',
              boxShadow: '8px 6px 24px -9px rgba(136, 136, 136, 1)',
            }}
          >
            Generate PDF
          </Button>
        )}
        {step === 'done' && (
          <Button
            onClick={onDownload}
            style={{
              backgroundColor: '#1976d2', // Blue color
              color: 'white',
              borderRadius: '20px',
              margin: '0 auto',
              padding: '0.5rem 2rem',
              fontWeight: 'bold',
              boxShadow: '8px 6px 24px -9px rgba(136, 136, 136, 1)',
            }}
          >
            Download
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default GenerateReportDialog;
