import { createBrowserRouter } from 'react-router-dom';
import ErrorPage from 'pages/common/ErrorPage/ErrorPage';
import { ProtectedRoute } from 'router/ProtectedRoute/ProtectedRoute';
import { AccessRoles } from 'shared/auth';
import Poland from 'pages/calculator/Poland/Poland';
import BalticCountries from 'pages/calculator/BalticCountries/BalticCountries';
import DigitalFairValue from 'pages/calculator/DigitalFairValue/DigitalFairValue';
import FairValue from 'pages/calculator/FairValue/FairValue';
import Root from './routes/Root';
import Calculator from './routes/Calculator';
import routes from './routes';

const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <ErrorPage />,
    element: <Root />,
    children: [
      {
        path: '/',
        element: <Calculator />,
        children: [
          {
            path: routes.poland,
            element: (
              <ProtectedRoute
                allowedRoles={[
                  AccessRoles.FULL_ACCESS,
                  AccessRoles.ONLINE_CAMPAIGNS,
                ]}
              >
                <Poland />
              </ProtectedRoute>
            ),
          },
          {
            path: routes.balticCountries,
            element: (
              <ProtectedRoute
                allowedRoles={[
                  AccessRoles.FULL_ACCESS,
                  AccessRoles.ONLINE_CAMPAIGNS,
                ]}
              >
                <BalticCountries />
              </ProtectedRoute>
            ),
          },
          {
            path: routes.fairValue,
            element: (
              <ProtectedRoute
                allowedRoles={[AccessRoles.FULL_ACCESS, AccessRoles.FAIR_VALUE]}
              >
                <FairValue />
              </ProtectedRoute>
            ),
          },
          {
            path: routes.digitalFairValue,
            element: (
              <ProtectedRoute
                allowedRoles={[
                  AccessRoles.FULL_ACCESS,
                  AccessRoles.DIGITAL_FAIR_VALUE,
                ]}
              >
                <DigitalFairValue />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },
]);

export default router;
