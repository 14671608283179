import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Header from 'shared/components/Header/Header';
import { StyledCard } from '../../styles';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:last-child': {
    marginBottom: '50px',
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.tableBackground.main,
  },
}));

const StyledTableBody = styled(TableBody)(({ theme }) => ({
  '&:last-child': {
    borderTop: '50px solid white',
  },
  '&:last-child tr': {
    '&:nth-of-type(even)': {
      backgroundColor: 'white',
    },
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.tableBackground.main,
    },
  },
  '&:last-child tr ul': {
    listStyle: 'none',
  },
}));

const StyledTableTitles = styled(TableCell)(({ theme }) => ({
  color: theme.palette.textDark.main,
  textTransform: 'uppercase',
}));

function CampainTypes() {
  const { t } = useTranslation();
  const table = 'modal.campaignTypes.table';

  const rows = [
    {
      id: `${t(`${table}.newProduct.type`)}`,
      data: [
        {
          type: `${t(`${table}.newProduct.type`)}`,
          availability: [
            `${t(`${table}.newProduct.availability.acd`)}`,
            `${t(`${table}.newProduct.availability.cpd`)}`,
            `${t(`${table}.newProduct.availability.ppd`)}`,
          ],
          desc: `${t(`${table}.newProduct.description`)}`,
          time: `${t(`${table}.newProduct.durationTime`)}`,
          general: [
            `${t(`${table}.newProduct.mainPoints.yt`)}`,
            `${t(`${table}.newProduct.mainPoints.fb`)}`,
            `${t(`${table}.newProduct.mainPoints.tt`)}`,
          ],
        },
        {
          type: `${t(`${table}.newCommunication.type`)}`,
          availability: [
            `${t(`${table}.newCommunication.availability.acd`)}`,
            `${t(`${table}.newCommunication.availability.cpd`)}`,
            `${t(`${table}.newCommunication.availability.ppd`)}`,
            `${t(`${table}.newCommunication.availability.lpd`)}`,
          ],
          desc: `${t(`${table}.newCommunication.description`)}`,
          time: `${t(`${table}.newCommunication.durationTime`)}`,
          general: [
            `${t(`${table}.newCommunication.mainPoints.yt`)}`,
            `${t(`${table}.newCommunication.mainPoints.fb`)}`,
            `${t(`${table}.newCommunication.mainPoints.tt`)}`,
          ],
        },
        {
          type: `${t(`${table}.constantCommunication.type`)}`,
          availability: [
            `${t(`${table}.constantCommunication.availability.acd`)}`,
            `${t(`${table}.constantCommunication.availability.cpd`)}`,
            `${t(`${table}.constantCommunication.availability.ppd`)}`,
            `${t(`${table}.constantCommunication.availability.lpd`)}`,
          ],
          desc: `${t(`${table}.constantCommunication.description`)}`,
          time: `${t(`${table}.constantCommunication.durationTime`)}`,
          general: [
            `${t(`${table}.constantCommunication.mainPoints.yt`)}`,
            `${t(`${table}.constantCommunication.mainPoints.fb`)}`,
            `${t(`${table}.constantCommunication.mainPoints.tt`)}`,
          ],
        },
        {
          type: `${t(`${table}.supportAwarness.type`)}`,
          availability: [
            `${t(`${table}.supportAwarness.availability.acd`)}`,
            `${t(`${table}.supportAwarness.availability.cpd`)}`,
            `${t(`${table}.supportAwarness.availability.ppd`)}`,
          ],
          desc: `${t(`${table}.supportAwarness.description`)}`,
          time: `${t(`${table}.supportAwarness.durationTime`)}`,
          general: [
            `${t(`${table}.supportAwarness.mainPoints.yt`)}`,
            `${t(`${table}.supportAwarness.mainPoints.fb`)}`,
            `${t(`${table}.supportAwarness.mainPoints.tt`)}`,
          ],
        },
      ],
    },
    {
      id: `${t(`${table}.newRange.type`)}`,
      data: [
        {
          type: `${t(`${table}.newRange.type`)}`,
          availability: [`${t(`${table}.newRange.availability.lpd`)}`],
          desc: `${t(`${table}.newRange.description`)}`,
          time: `${t(`${table}.newRange.durationTime`)}`,
          general: [
            `${t(`${table}.newRange.mainPoints.yt`)}`,
            `${t(`${table}.newRange.mainPoints.fb`)}`,
            `${t(`${table}.newRange.mainPoints.tt`)}`,
          ],
        },
        {
          type: `${t(`${table}.existingRange.type`)}`,
          availability: [`${t(`${table}.existingRange.availability.lpd`)}`],
          desc: `${t(`${table}.existingRange.description`)}`,
          time: `${t(`${table}.existingRange.durationTime`)}`,
          general: [
            `${t(`${table}.existingRange.mainPoints.yt`)}`,
            `${t(`${table}.existingRange.mainPoints.fb`)}`,
            `${t(`${table}.existingRange.mainPoints.tt`)}`,
          ],
        },
        {
          type: `${t(`${table}.supportOfferLPD.type`)}`,
          availability: [`${t(`${table}.supportOfferLPD.availability.lpd`)}`],
          desc: `${t(`${table}.supportOfferLPD.description`)}`,
          time: `${t(`${table}.supportOfferLPD.durationTime`)}`,
          general: [
            `${t(`${table}.supportOfferLPD.mainPoints.yt`)}`,
            `${t(`${table}.supportOfferLPD.mainPoints.fb`)}`,
            `${t(`${table}.supportOfferLPD.mainPoints.tt`)}`,
          ],
        },
        {
          type: `${t(`${table}.trafficSale.type`)}`,
          availability: [`${t(`${table}.trafficSale.availability.lpd`)}`],
          desc: `${t(`${table}.trafficSale.description`)}`,
          time: `${t(`${table}.trafficSale.durationTime`)}`,
          general: [
            `${t(`${table}.trafficSale.mainPoints.yt`)}`,
            `${t(`${table}.trafficSale.mainPoints.fb`)}`,
            `${t(`${table}.trafficSale.mainPoints.tt`)}`,
          ],
        },
      ],
    },
  ];

  return (
    <StyledCard>
      <Header title={t(`modal.campaignTypes.typesOfCampaigns`)} />
      <StyledCard sx={{ paddingLeft: 0 }}>
        <TableContainer>
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                <StyledTableTitles sx={{ paddingLeft: '30px' }}>
                  {t(`${table}.headTypes`)}
                </StyledTableTitles>
                <StyledTableTitles align="left" sx={{ paddingRight: '50px' }}>
                  {t(`${table}.headAvailability`)}
                </StyledTableTitles>
                <StyledTableTitles align="left" sx={{ paddingLeft: '100px' }}>
                  {t(`${table}.headDesc`)}
                </StyledTableTitles>
                <StyledTableTitles align="left" sx={{ textAlign: 'center' }}>
                  {t(`${table}.headTime`)}
                </StyledTableTitles>
                <StyledTableTitles align="center">
                  {t(`${table}.headMainPoints`)}
                </StyledTableTitles>
              </TableRow>
            </TableHead>
            {rows.map((row) => {
              return (
                <StyledTableBody sx={{ marginBottom: '50px' }} key={row.id}>
                  {row.data.map((el) => (
                    <StyledTableRow key={el.type + el.desc}>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ paddingLeft: '30px' }}
                      >
                        {el.type}
                      </TableCell>
                      <TableCell align="left">
                        {el.availability.map((e) => {
                          return (
                            <ul key={e}>
                              <li>{e}</li>
                            </ul>
                          );
                        })}
                      </TableCell>
                      <TableCell align="left" sx={{ minWidth: '250px' }}>
                        {el.desc}
                      </TableCell>
                      <TableCell align="center">{el.time}</TableCell>
                      <TableCell align="left" sx={{ minWidth: '200px' }}>
                        {el.general.map((e) => {
                          return (
                            <ul key={e} style={{ listStyle: 'none' }}>
                              <li>{e}</li>
                            </ul>
                          );
                        })}
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </StyledTableBody>
              );
            })}
          </Table>
        </TableContainer>
      </StyledCard>
    </StyledCard>
  );
}

export default CampainTypes;
