import { styled } from '@mui/material';
import { useRouteError } from 'react-router-dom';

export const ErrorPageWrapper = styled('div')({
  textAlign: 'center',
});

interface Error {
  statusText: string;
  message: string;
}

export default function ErrorPage() {
  const error = useRouteError() as Error;
  console.error(error);

  return (
    <ErrorPageWrapper>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error?.statusText || error?.message}</i>
      </p>
    </ErrorPageWrapper>
  );
}
