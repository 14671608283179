import { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { buildYupLocale } from 'config/yup';

function LanguageMenu() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSwitchLanguage = (lang: string) => () => {
    localStorage.setItem('lang', lang);
    i18n.changeLanguage(lang);
    buildYupLocale(null, t);
    handleMenuClose();
  };

  return (
    <>
      <Button
        color="secondary"
        aria-controls={anchorEl ? 'language-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? 'true' : undefined}
        onClick={handleMenuOpen}
      >
        {i18n.language.toUpperCase()}
        <ArrowDropDownIcon sx={{ width: '18px', height: '18px' }} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleSwitchLanguage('pl')}>
          <Typography variant="body1">PL</Typography>
        </MenuItem>
        <MenuItem onClick={handleSwitchLanguage('en')}>
          <Typography variant="body1">EN</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export default LanguageMenu;
