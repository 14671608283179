import { Checkbox, TableRow, Typography } from '@mui/material';
import { ChangeEvent, Fragment } from 'react';
import { StyledDivider, StyledHeaderCell, StyledRowCell } from '../styles';
import { TableProps } from '../types';

export const renderColumns = ({
  shouldDisableCheckAll,
  handleToggleAll,
  columns,
}: {
  columns: TableProps['columns'];
  shouldDisableCheckAll: boolean;
  handleToggleAll: (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <TableRow>
      <StyledHeaderCell padding="checkbox">
        <Checkbox
          color="primary"
          disabled={shouldDisableCheckAll}
          onChange={handleToggleAll}
        />
      </StyledHeaderCell>
      {columns.map(({ key, label, dividerColumn, width, align }) => (
        <Fragment key={key}>
          <StyledHeaderCell sx={{ width }} align={align}>
            <Typography
              color="textDark.main"
              variant="caption"
              textTransform="uppercase"
              fontWeight={600}
            >
              {label}
            </Typography>
          </StyledHeaderCell>
          {dividerColumn && (
            <StyledRowCell sx={{ padding: 0 }}>
              <StyledDivider orientation="vertical" />
            </StyledRowCell>
          )}
        </Fragment>
      ))}
    </TableRow>
  );
};
