export const headerStyles = {
  font: {
    sz: 14,
    bold: true,
  },
  border: {
    bottom: {
      style: 'medium',
    },
  },
};

export const footerBudgetStyles = {
  border: {
    right: {
      style: 'medium',
    },
  },
};

export const footerStyles = {
  border: {
    bottom: {
      style: 'medium',
    },
  },
};
