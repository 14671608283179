import { CountryData, ParametersFormData } from 'store/countriesDataStore';
import XLSX from 'sheetjs-style';
import { Countries } from 'shared/enums';
import { formatCurrency, formatNumber } from 'shared/utils';
import {
  DATE_FORMATS,
  parametersBaltic,
  parametersPoland,
} from 'shared/constants';
import { cloneDeep, omit } from 'lodash';
import { TFunction } from 'i18next';
import moment from 'moment';
import { footerBudgetStyles, footerStyles, headerStyles } from './constants';
import generateTouchpointValue from './generateTouchpointValue';

export default (
  countryData: CountryData,
  filteredEstimatedBudgetData: CountryData['estimatedBudget'],
  formData: ParametersFormData,
  country: Countries,
  firstWhitespacesCount: number,
  secondWhitespacesCount: number,
  t: TFunction<'translation', undefined, 'translation'>
) => {
  const { parameters } = countryData;

  const getTranslatedParameters = (params: string[]) =>
    params.map((parameter) => t(`calculator.parameters.${parameter}`));

  const emptyRowsFirst = Array.from(
    { length: firstWhitespacesCount },
    () => []
  );
  const emptyRowsSecond = Array.from(
    { length: secondWhitespacesCount },
    () => []
  );

  const formattedFormData = cloneDeep(formData);

  if (formattedFormData.campaignEndDate) {
    formattedFormData.campaignEndDate = moment(
      formattedFormData.campaignEndDate
    ).format(DATE_FORMATS.BY_SPACE);
  }
  if (formattedFormData.campaignStartDate) {
    formattedFormData.campaignStartDate = moment(
      formattedFormData.campaignStartDate
    ).format(DATE_FORMATS.BY_SPACE);
  }

  const formDataOrder = [
    'divisionName',
    'campaignTypeName',
    'campaignStartDate',
    'campaignDuration',
    'campaignEndDate',
    'campaignBumper',
    'budget',
    'youtubeTargetGroup',
    'metaTargetGroup',
    'tiktokTargetGroup',
  ];
  const orderedFormData = formDataOrder.reduce((result, key) => {
    if (Object.prototype.hasOwnProperty.call(formattedFormData, key)) {
      return {
        ...result,
        [key]: formattedFormData[key as keyof ParametersFormData],
      };
    }
    return result;
  }, {});

  const ws = XLSX.utils.aoa_to_sheet([
    [
      ...Object.keys(omit(orderedFormData, ['touchpoints'])).map((param) =>
        t(`calculator.form.${param}`)
      ),
    ],
    [...Object.values(omit(orderedFormData, ['touchpoints']))],
    ...emptyRowsFirst,
    [
      t('calculator.table.columns.touchpoint'),
      t('calculator.table.columns.phase'),
      t('calculator.table.columns.priority'),
      t('calculator.table.columns.budget'),
      t('calculator.table.columns.impressions'),
      t('calculator.table.columns.reach'),
      t('calculator.table.columns.clicks'),
      t('calculator.table.columns.videoViews'),
    ],
    ...filteredEstimatedBudgetData.map((eb) => [
      eb.touchpointName,
      eb.touchpointFunnel,
      eb.isMustHave ? 'Must have' : 'Optional',
      formatCurrency(eb.budget, 'PLN'),
      formatNumber(eb.impressions),
      formatNumber(eb.reach),
      formatNumber(eb.clicks),
      formatNumber(eb.videoViews),
    ]),
    ...emptyRowsSecond,
    [t('parameter'), 'YouTube', 'Meta', 'TikTok', t('comment')],
    ...(country === Countries.POLAND
      ? getTranslatedParameters(parametersPoland)
      : getTranslatedParameters(parametersBaltic)
    ).map((x, i) => [
      x,
      ...[
        parameters[i].youtube,
        parameters[i].meta,
        parameters[i].tiktok,
        Object.values(omit(parameters[i], 'id')).some(
          (c) => typeof c === 'boolean' && !c
        )
          ? 'comment'
          : null,
      ].map((touchpoint) => generateTouchpointValue(touchpoint, t)),
    ]),
  ]);

  const formDataColumnCount = Object.keys(
    omit(formData, ['touchpoints'])
  ).length;
  for (let i = 0; i < formDataColumnCount; i++) {
    const column = String.fromCharCode(65 + i);
    ws[`${column}${1}`].s = headerStyles;
  }

  const firstRowIndex = firstWhitespacesCount + 4;

  const estimatedBudgetColumnLength =
    Object.keys(omit(filteredEstimatedBudgetData[0], ['id', 'techCost']))
      .length - 1;
  for (let i = 0; i <= estimatedBudgetColumnLength; i++) {
    const column = String.fromCharCode(65 + i);

    if (column === 'D') {
      ws[`${column}${firstRowIndex - 1}`].s = {
        ...headerStyles,
        border: {
          ...footerBudgetStyles.border,
          ...footerStyles.border,
        },
      };
    } else {
      ws[`${column}${firstRowIndex - 1}`].s = headerStyles;
    }
  }

  Object.keys(filteredEstimatedBudgetData[0]).forEach((key, index) => {
    if (ws[`D${index + firstRowIndex}`]) {
      ws[`D${index + firstRowIndex}`].s = footerBudgetStyles;
    }

    if (ws[`C${index + firstRowIndex}`]) {
      ws[`C${index + firstRowIndex}`].s = {
        font: {
          color: ws[`C${index + firstRowIndex}`].v.includes('Optional')
            ? { rgb: '2F80ED' }
            : { rgb: '8043B9' },
        },
      };
    }
  });
  return ws;
};
