import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface PriorityProps {
  isMustHave: boolean;
}

function Priority({ isMustHave }: PriorityProps) {
  const { t } = useTranslation();
  const translatedPriotity = t(
    `priorities.${isMustHave ? 'mustHave' : 'optional'}`
  );

  return (
    <Typography
      color={isMustHave ? 'secondary' : 'lorealBlue.main'}
      fontSize="0.8rem"
      fontWeight={700}
      textTransform="uppercase"
    >
      {translatedPriotity}
    </Typography>
  );
}

export default Priority;
