import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { InfoOutlined, LogoutOutlined } from '@mui/icons-material';
import { Box, Stack, Tab, styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ModalWindow from 'shared/components/Modal/ModalWindow';
import InstructionContent from 'shared/components/AppBar/components/InstructionContent/InstructionContent';
import CloseIcon from '@mui/icons-material/Close';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import LanguageMenu from './components/LanguageMenu/LanguageMenu';
import NavButton from './components/NavButton/NavButton';
import CampaignTypes from '../CampaignTypes/CampaignTypes';

const StyledTab = styled(Tab)({
  textTransform: 'none',
});

const StyledTabPanel = styled(TabPanel)({
  padding: '20px',
  overflow: 'auto',
  height: '80vh',
});

function Navigation() {
  const theme = useTheme();
  const { logout } = useAuth0();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [value, setValue] = useState('1');

  const { t } = useTranslation();

  const handleButtonClick = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const closeAndReset = () => {
    setValue('1');
    handleButtonClick();
  };

  return (
    <Stack component="nav" direction="row" spacing="1rem">
      {/* <NavButton
        icon={<InfoOutlined />}
        color="secondary"
        onClick={handleButtonClick}
      /> */}
      <LanguageMenu />
      <NavButton
        icon={<LogoutOutlined />}
        color="secondary"
        onClick={() =>
          logout({ logoutParams: { returnTo: window.location.origin } })
        }
      />
      <ModalWindow open={isModalOpen} onClose={closeAndReset}>
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: `1px solid ${theme.palette.border.main}`,
              display: 'flex',
              justifyContent: 'space-between',
              position: 'sticky',
            }}
          >
            <TabList value={value} onChange={handleChange}>
              <StyledTab label={t('modal.tabs.instruction')} value="1" />
              <StyledTab label={t('modal.tabs.campains')} value="2" />
              <StyledTab label={t('modal.tabs.seasonality')} value="3" />
            </TabList>
            <CloseIcon
              onClick={closeAndReset}
              style={{
                color: `${theme.palette.textLight.main}`,
                cursor: 'pointer',
              }}
            />
          </Box>
          <StyledTabPanel value="1">
            <InstructionContent />
          </StyledTabPanel>
          <StyledTabPanel value="2">
            <CampaignTypes />
          </StyledTabPanel>
          <StyledTabPanel value="3">Sezonowość stawek</StyledTabPanel>
        </TabContext>
      </ModalWindow>
    </Stack>
  );
}

export default Navigation;
