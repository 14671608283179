import { Box, styled } from '@mui/material';

export const Wrapper = styled('div')({
  margin: '2rem -2rem',
  width: 'calc(100% + 4rem)',
});

export const TotalRow = styled(Box)(({ theme }) => ({
  padding: '1rem 2rem 0.5rem 5.5rem',
  backgroundColor: theme.palette.totalBackground.main,
  height: '7rem',
}));
