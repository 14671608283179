import { TFunction } from 'i18next';
import { sumBy } from 'lodash';
import { EMPTY_VALUE } from 'shared/constants';
import { formatCurrency, formatNumber } from 'shared/utils';
import { CountryData } from 'store/countriesDataStore';

export default (
  filteredData: CountryData['estimatedBudget'],
  isMustHave: boolean,
  columnNames: string[],
  t: TFunction<'translation', undefined, 'translation'>
) => {
  const mustHaveFiltered = filteredData.filter(
    (x) => x.isMustHave === isMustHave
  );
  return [
    isMustHave ? t('budgetMustHave') : t('optionalTouchpoints'),
    formatCurrency(sumBy(mustHaveFiltered, 'budget'), 'PLN'),
    formatNumber(sumBy(mustHaveFiltered, columnNames[0])),
    EMPTY_VALUE,
    formatNumber(sumBy(mustHaveFiltered, columnNames[2])),
    formatNumber(sumBy(mustHaveFiltered, columnNames[3])),
  ];
};
