/* eslint-disable camelcase */
import { fetchData } from '../utils';
import { ApiResponse } from '../types';

export const CONFIG_KEY = 'config';

export const getExchangeRate = async () => {
  return fetchData<
    ApiResponse<{
      exchange_rate_eurpln: number;
    }>
  >(`/${CONFIG_KEY}`);
};
