import { fetchData, postData } from '../utils';
import { ApiResponse } from '../types';

export const FAIR_VALUE_KEY = 'fair-value';

export const getCampaignTG = async () => {
  return fetchData<
    ApiResponse<{
      campaignTargetGroups: string[];
    }>
  >(`/${FAIR_VALUE_KEY}/target-groups`);
};

export const getSuggestedLorealTG = async (targetGroupName: string) => {
  return fetchData<
    ApiResponse<{
      suggestedReferringGroups: {
        targetGroupName: string;
        suggestedGroup: boolean;
      }[];
    }>
  >(`/${FAIR_VALUE_KEY}/referring-target-groups`, {
    params: { targetGroupName },
  });
};

export const getCopyLength = async () => {
  return fetchData<
    ApiResponse<{
      copyLength: string[];
    }>
  >(`/${FAIR_VALUE_KEY}/copy-lengths`);
};

export const checkFairValue = async (body: {
  periods: {
    campaignMonth: string;
    campaignTG: string;
    suggestedLorealTG: string;
    copyLength: number;
    lorealCommLength: number;
    grpNatural: number;
  }[];
  campaignValue: number;
}) => {
  return postData<ApiResponse<{ isFairValue: boolean }>>(
    `/${FAIR_VALUE_KEY}/verify`,
    body
  );
};
