import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { buildYupLocale } from './yup';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init(
    {
      backend: {
        loadPath: '/locales/{{lng}}.json',
        requestOptions: {
          cache: 'no-store',
        },
      },

      lng: localStorage.getItem('lang') || 'en',
      fallbackLng: 'en',
      interpolation: {
        skipOnVariables: true,
      },
    },
    buildYupLocale
  );

export default i18n;
