import { AxiosRequestConfig } from 'axios';
import axios from 'config/axios';

export const fetchData = async <T>(
  url: string,
  config?: AxiosRequestConfig
): Promise<T> => {
  const response = await axios.get<T>(url, config);
  return response.data;
};

export const postData = async <T>(
  url: string,
  body: unknown,
  config?: AxiosRequestConfig
): Promise<T> => {
  const response = await axios.post<T>(url, body, config);
  return response.data;
};
