export const DATE_FORMATS = {
  BY_SPACE: 'DD MMMM YYYY',
  BY_HYPHEN: 'YYYY-MM-DD',
};

export const EMPTY_VALUE = '-';

export const PERIODS = [
  '1-1',
  '1-2',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12-1',
  '12-2',
];

export const parametersBaltic = [
  'tgSizeAccordingToPopulation',
  'tgSizeAccordingToPanel',
  'selectedEstimationSource',
  'tgSizeAccordingToYT',
  'programmaticReachIndex',
];

export const parametersPoland = [...parametersBaltic, 'facebookMinimumReach'];
