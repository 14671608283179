import { Moment } from 'moment';
import { OptionalIds } from 'shared/components/EstimatedBudgetsTable/types';
import { Countries } from 'shared/enums';
import { AnyFunc, Row, Touchpoints } from 'shared/types';
import { getRowIdsByPriority } from 'shared/utils';
import { create } from 'zustand';

export interface ParametersFormData {
  budget?: number;
  divisionName: string;
  campaignTypeName: string;
  campaignStartDate: Moment | object | string;
  youtubeTargetGroup: string;
  metaTargetGroup: string;
  tiktokTargetGroup: string;
  campaignBumper: number;
  campaignDuration: number | null;
  campaignEndDate: Moment | object | string;
  touchpoints: Touchpoints[];
}

export interface CountryData {
  formData: ParametersFormData;
  estimatedBudget: Row[];
  parameters: Row[];
  optionalIds: OptionalIds;
  reset: () => void;
  updateForm: (newForm?: ParametersFormData) => void;
  updateEstimatedBudget: (estimatedBudget: Row[]) => void;
  updateParameters: (parameters: Row[]) => void;
  updateOptionalIds: (newOptionalIds: OptionalIds) => void;
}

export interface StoreCountriesData {
  [Countries.POLAND]: CountryData;
  [Countries.LITHUANIA]: CountryData;
  [Countries.LATVIA]: CountryData;
  [Countries.ESTONIA]: CountryData;
}

const INITIAL_DATA = {
  estimatedBudget: [],
  parameters: [],
  optionalIds: getRowIdsByPriority([], false),
  formData: {} as ParametersFormData,
};

const getInitialState = (country: Countries, set: AnyFunc) => {
  return {
    ...INITIAL_DATA,
    reset: () => {
      set((state: StoreCountriesData) => ({
        ...state,
        [country]: {
          ...state[country],
          ...INITIAL_DATA,
        },
      }));
    },
    updateForm: (newForm?: ParametersFormData) =>
      set((state: StoreCountriesData) => ({
        ...state,
        [country]: {
          ...state[country],
          formData: newForm || ({} as ParametersFormData),
        },
      })),
    updateEstimatedBudget: (estimatedBudget: Row[]) => {
      set((state: StoreCountriesData) => ({
        ...state,
        [country]: {
          ...state[country],
          estimatedBudget,
        },
      }));
    },
    updateParameters: (parameters: Row[]) => {
      set((state: StoreCountriesData) => ({
        ...state,
        [country]: {
          ...state[country],
          parameters,
        },
      }));
    },
    updateOptionalIds: (optionalIds: OptionalIds) => {
      set((state: StoreCountriesData) => ({
        ...state,
        [country]: {
          ...state[country],
          optionalIds,
        },
      }));
    },
  };
};

export const useCountriesDataStore = create<StoreCountriesData>((set) => ({
  [Countries.POLAND]: getInitialState(Countries.POLAND, set),
  [Countries.LITHUANIA]: getInitialState(Countries.LITHUANIA, set),
  [Countries.LATVIA]: getInitialState(Countries.LATVIA, set),
  [Countries.ESTONIA]: getInitialState(Countries.ESTONIA, set),
}));
