import { Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { shallow } from 'zustand/shallow';
import { useDigitalFairValueFormStore } from 'store/digitalFairValueFormStore';
import { FormSelect } from 'shared/components/Select/Select';
import {
  TV_FAIR_VALUE_KEY,
  getBrands,
  getDivisions,
  getPartners,
} from 'api/services/tvFairValue';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import { DATE_FORMATS } from 'shared/constants';
import { useTranslation } from 'react-i18next';
import MultiSelectAutocomplete from './MultiSelectAutocomplete';
import { DigitalFairValueDatePicker } from '../styles';

function Step1() {
  const { control, watch, setValue } = useFormContext();
  const { divisions: selectedDivisions } = watch();

  const { t } = useTranslation();

  const { data: divisionsResponse } = useQuery(
    [TV_FAIR_VALUE_KEY, 'divisions'],
    getDivisions
  );
  const divisions = (divisionsResponse?.data.divisions || []).map(
    (division, i) => ({ id: i, title: division })
  );

  const { data: brandsResponse } = useQuery({
    queryKey: [
      TV_FAIR_VALUE_KEY,
      'brands',
      (selectedDivisions || []).join(','),
    ],
    queryFn: () =>
      getBrands(selectedDivisions.map((x: { title: string }) => x.title)),
    enabled: !isEmpty(divisions),
  });

  const brands = (brandsResponse?.data.brands || []).map((brand, i) => ({
    id: i,
    title: brand,
  }));
  const { data: partnersResponse } = useQuery(
    [TV_FAIR_VALUE_KEY, 'partners'],
    getPartners
  );

  const handleChange = (value: any, name: string) => {
    setValue(name, value);
  };

  return (
    <Grid container item sx={{ gap: '2rem' }}>
      <Grid item xs={3}>
        <Controller
          name="divisions"
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <MultiSelectAutocomplete
                options={divisions}
                id="divisions"
                label={t('digitalFairValue.division')}
                noOptionsText="There are no divisions"
                onChange={(_: any, value: any) =>
                  handleChange(value, 'divisions')
                }
                value={field.value}
                initialOptions={[]}
                limitTags={0}
                disableClearable={false}
                disabled={false}
                error={!!error}
                helperText={error?.message}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          name="brands"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <MultiSelectAutocomplete
              options={brands}
              id="brands"
              label={t('digitalFairValue.brand')}
              noOptionsText="There are no brands"
              onChange={(_: any, value: any) => handleChange(value, 'brands')}
              value={field.value}
              initialOptions={[]}
              limitTags={0}
              disableClearable={false}
              disabled={false}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <FormSelect
          fullWidth
          name="partner"
          control={control}
          onChange={(event: any) =>
            handleChange(event.target.value as string, 'partner')
          }
          options={partnersResponse?.data.partners || []}
          label={t('digitalFairValue.partner')}
        />
      </Grid>
    </Grid>
  );
}

export default Step1;
