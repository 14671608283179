import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material';
import TabLink from './components/TabLink/TabLink';

const Wrapper = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[400]}`,
  width: '100%',
}));

interface NavTabsProps {
  paths: { label: string; to: string; show: boolean }[];
}

export default function NavTabs(props: NavTabsProps) {
  const { paths } = props;
  const location = useLocation();

  const activeIndex = paths.findIndex(
    (path) => location.pathname.replace('/', '') === path.to
  );

  const isExistingPath = activeIndex !== -1;

  return (
    <Wrapper>
      <Tabs value={isExistingPath ? activeIndex : false} aria-label="nav tabs">
        {paths.map((path) => (
          <TabLink key={path.to} label={path.label} to={path.to} />
        ))}
      </Tabs>
    </Wrapper>
  );
}
