import { useAuth0 } from '@auth0/auth0-react';
import { setAuthToken } from 'config/axios';
import LoadingPage from 'pages/common/LoadingPage/LoadingPage';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { MediaCalculatorUser } from 'shared/auth';
import AppBar from 'shared/components/AppBar/AppBar';

function Root() {
  const auth0 = useAuth0<MediaCalculatorUser>();
  const { isAuthenticated, isLoading, loginWithRedirect, getIdTokenClaims } =
    auth0;

  useEffect(() => {
    const login = async () => {
      if (!isAuthenticated && !isLoading) {
        await loginWithRedirect();
      }
      const idToken = await getIdTokenClaims();
      if (idToken) {
        // eslint-disable-next-line no-underscore-dangle
        setAuthToken(idToken.__raw);
      }
    };

    login();
  }, [getIdTokenClaims, isAuthenticated, isLoading, loginWithRedirect]);

  if (!isAuthenticated || isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <AppBar />
      <Outlet />
    </>
  );
}

export default Root;
