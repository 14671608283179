import {
  Box,
  Button,
  Card,
  FormHelperText,
  Grid,
  styled,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

export const HeaderWrapper = styled(Box)({
  marginBottom: '2rem',
});

export const StyledCard = styled(Card)({
  width: '100%',
  padding: '1.5rem',
  marginTop: '2rem',
});

export const Row = styled(Grid)({
  gap: '2rem',
  marginBottom: '1rem',
});

export const HelperText = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.primary.main,
  paddingLeft: '1rem',
}));
export const PeriodHeader = styled(Typography)(({ theme }) => ({
  margin: 'auto 0',
  fontWeight: 600,
  textTransform: 'uppercase',
  color: theme.palette.textDark.main,
}));

export const CampaignValueCard = styled('fieldset')(({ theme }) => ({
  padding: '2rem 1.5rem 1.5rem 1.5rem',
  border: `1px solid ${theme.palette.borderDark.main}`,
  borderRadius: '10px',
  width: 'fit-content',
  position: 'relative',
  marginTop: '3rem',
}));

export const Label = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '-0.6rem',
  left: '0.8rem',
  color: theme.palette.textDark.main,
  backgroundColor: theme.palette.background.paper,
  padding: '0 0.5rem',
  fontSize: '0.9rem',
  fontWeight: 600,
  lineHeight: 1,
  letterSpacing: '0.00938em',
  textTransform: 'uppercase',
}));

export const CampaignIcon = styled(SvgIcon)({
  marginTop: '0.4rem',
  fontSize: '2.5rem',
});

export const CheckButton = styled(Button)({
  height: '2.3rem',
  marginTop: '0.6rem',
  width: '10rem',
});

export const DigitalFairValueDatePicker = styled(DatePicker)({
  width: '100%',
});

export const StyledTooltip = styled(Tooltip)({
  marginLeft: '0.7rem',
});
