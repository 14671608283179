import { Button, styled } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

export const Wrapper = styled('div')({
  position: 'fixed',
  left: 0,
  zIndex: 999,
  width: 'auto',
  bottom: 0,
});

export const FloatingButton = styled(Button)({
  visibility: 'visible',
  width: 'auto',
  borderRadius: '10px 10px 0px 0px',
  padding: '0.3rem 1.5rem',
  justifyContent: 'space-between',
});

export const StyledDownloadIcon = styled(DownloadIcon)({
  color: 'white',
});
