import { fetchData, postData } from '../utils';
import { ApiResponse } from '../types';

export const TV_FAIR_VALUE_KEY = 'fair-value';

export const getDivisions = async () => {
  return fetchData<
    ApiResponse<{
      divisions: string[];
    }>
  >(`/${TV_FAIR_VALUE_KEY}/divisions`);
};

export const getBrands = async (divisions: string[]) => {
  return fetchData<
    ApiResponse<{
      brands: string[];
    }>
  >(`/${TV_FAIR_VALUE_KEY}/brands?divisions=${JSON.stringify(divisions)}`);
};

export const getPartners = async () => {
  return fetchData<
    ApiResponse<{
      partners: string[];
    }>
  >(`/${TV_FAIR_VALUE_KEY}/partners`);
};

export const getChannels = async (accessType: 'full' | 'partial') => {
  return fetchData<
    ApiResponse<{
      channels: Array<{ channel: string; benchmark: string }>;
    }>
  >(`/${TV_FAIR_VALUE_KEY}/channels?accessType=${accessType}`);
};

export const verifyTvFairValue = async (body: {
  currentStep: number;
  brandNames: string[];
  divisionNames: string[];
  partnerName: string;
  isDedIssued: boolean;
  isFullSalesDataReceived: boolean;
  dashboardExcel: string;
  periods: {
    id: string;
    campaignMonth: string;
    campaignMonthTranslation: string;
    campaignTG: string;
    suggestedLorealTG: string;
    copyLength: number;
    lorealCommLength: number;
    grpNatural: number;
  }[];
  campaignValue: number;
}) => {
  return postData<
    ApiResponse<{
      errors: Array<{
        rowId: string;
        channelName?: string;
        fields?: Array<string>;
      }>;
      isVerify: boolean;
      transactionId: string;
    }>
  >(`/${TV_FAIR_VALUE_KEY}/verify?step=${body.currentStep}`, body);
};
