import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

export const DigitalFairValueDatePicker = styled(DatePicker)({
  width: '100%',
});

export const StyledTooltip = styled(Tooltip)({
  marginLeft: '0.7rem',
});
