import { Checkbox, TableCellProps, TableRow, Theme } from '@mui/material';
import { groupBy } from 'lodash';
import { Fragment } from 'react';
import { EMPTY_VALUE } from 'shared/constants';
import {
  CheckboxOnChangeEvent,
  Column,
  TableRowOnClickEvent,
} from 'shared/types';
import { StyledDivider, StyledRowCell } from '../styles';
import { OptionalIds, TableProps } from '../types';

const renderCell = ({
  format,
  cell,
  renderer,
}: Partial<Column> & {
  cell: string | number;
}) => {
  const value = cell || EMPTY_VALUE;
  if (format) {
    return format(value, 'PLN');
  }
  if (renderer) {
    return renderer(cell);
  }
  return value;
};

export const renderRows = ({
  handleRowClick,
  optionalIds,
  theme,
  rows,
  columns,
}: {
  rows: TableProps['rows'];
  columns: TableProps['columns'];
  theme: Theme;
  optionalIds: OptionalIds;
  handleRowClick: (
    e: TableRowOnClickEvent | CheckboxOnChangeEvent,
    rowId: string
  ) => void;
}) => {
  const groupedRows = groupBy(rows, 'touchpointName');
  return Object.entries(groupedRows).flatMap(([_, groupRows], index) => {
    const rowColor =
      index % 2 === 0
        ? theme.palette.tableBackground.main
        : theme.palette.common.white;

    return groupRows.map((row) => (
      <TableRow
        key={row.id}
        onClick={(e) => handleRowClick(e, row.id)}
        sx={{ backgroundColor: rowColor }}
      >
        <StyledRowCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={row.isMustHave || optionalIds[row.id] || false}
            disabled={row.isMustHave}
            onChange={(e) => handleRowClick(e, row.id)}
          />
        </StyledRowCell>
        {columns.map(({ key, dividerColumn, format, align, renderer }) => (
          <Fragment key={`${row.id}${key}`}>
            <StyledRowCell align={align as TableCellProps['align']}>
              {renderCell({
                format,
                cell: row[key],
                renderer,
              })}
            </StyledRowCell>
            {dividerColumn && (
              <StyledRowCell sx={{ padding: 0 }}>
                <StyledDivider orientation="vertical" />
              </StyledRowCell>
            )}
          </Fragment>
        ))}
      </TableRow>
    ));
  });
};
