import Tab from '@mui/material/Tab';
import { Link, To } from 'react-router-dom';

interface LinkTabProps {
  label: string;
  to: To;
}

export default function TabLink(props: LinkTabProps) {
  return <Tab<typeof Link> component={Link} {...props} />;
}
