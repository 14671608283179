import {
  Divider,
  IconButton,
  Slide,
  Typography,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { formatCurrency } from 'shared/utils';
import { Countries } from 'shared/enums';
import { useRef, useState } from 'react';
import { StoreTotalBudget } from 'store/totalBudgetStore';

import { useCountriesDataStore } from 'store/countriesDataStore';
import { shallow } from 'zustand/shallow';
import { useTranslation } from 'react-i18next';

import excelExport from 'services/excelExport';
import {
  FloatingBox,
  FloatingButton,
  StyledDownloadIcon,
  Wrapper,
} from './styles';
import TotalBudget from './components/TotalBudget/TotalBudget';

function TotalBudgetWidget({
  data,
}: {
  data: Pick<
    StoreTotalBudget,
    | Countries.POLAND
    | Countries.LITHUANIA
    | Countries.LATVIA
    | Countries.ESTONIA
  >;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [checked, setChecked] = useState(true);
  const containerRef = useRef(null);

  const countriesData = useCountriesDataStore((state) => state, shallow);
  const formData = useCountriesDataStore((state) => state, shallow);

  const total = Object.entries(data).reduce(
    (acc, [_, budget]) => acc + ((budget.total || 0) as number),
    0
  );

  return (
    <Wrapper
      style={{
        bottom: checked ? '-31.7rem' : '-34.9rem',
      }}
      ref={containerRef}
    >
      <Slide in={checked} appear={false} container={containerRef.current}>
        <div>
          <FloatingButton
            variant="contained"
            disableRipple
            startIcon={checked ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            endIcon={
              <IconButton
                component="div"
                onClick={(e) => {
                  e.stopPropagation();
                  excelExport(countriesData, formData, t);
                }}
              >
                <StyledDownloadIcon />
              </IconButton>
            }
            onClick={() => setChecked(!checked)}
          >
            <Typography
              color="white"
              fontWeight={900}
              textTransform="uppercase"
              variant="body2"
              style={{
                flexGrow: 1,
                marginRight: '0rem',
              }}
            >
              Total budget:
            </Typography>
            <Typography
              color="white"
              variant="body2"
              fontWeight={900}
              textTransform="uppercase"
              style={{
                flexGrow: 1,
                marginRight: '0rem',
              }}
            >
              {formatCurrency(total, 'PLN')}
            </Typography>
          </FloatingButton>

          <FloatingBox>
            <TotalBudget
              country={Countries.POLAND}
              budget={data[Countries.POLAND]}
            />
            <Divider color={theme.palette.border.main} />
            <TotalBudget
              country={Countries.LITHUANIA}
              budget={data[Countries.LITHUANIA]}
            />
            <Divider color={theme.palette.border.main} />
            <TotalBudget
              country={Countries.LATVIA}
              budget={data[Countries.LATVIA]}
            />
            <Divider color={theme.palette.border.main} />
            <TotalBudget
              country={Countries.ESTONIA}
              budget={data[Countries.ESTONIA]}
            />
          </FloatingBox>
        </div>
      </Slide>
    </Wrapper>
  );
}

export default TotalBudgetWidget;
