import { debounce } from 'lodash';
import { calcClicks, calcImpressions } from './utils';

const debouncedSetValue = (
  key: string,
  value: number | string,
  setValue: any
) => debounce(() => setValue(key, value), 700);

export const benchmarks = {
  cpm: [
    {
      value: 'impressions',
      disabled: true,
    },
    {
      value: 'cpm',
      onChange: (
        setValue: any,
        name: string,
        value: string,
        index: number,
        channel: any
      ) => {
        setValue(name, value);
        if (channel.cost > 0 && Number(value) > 0) {
          const impressions = calcImpressions(channel.cost, Number(value));
          debouncedSetValue(
            `partialChannels[${index}].impressions`,
            impressions,
            setValue
          )();
        } else {
          setValue(`partialChannels[${index}].impressions`, 0);
        }
      },
    },
    {
      value: 'cost',
      onChange: (
        setValue: any,
        name: string,
        value: string,
        index: number,
        channel: any
      ) => {
        setValue(name, value);
        if (channel.cpm > 0 && Number(value) > 0) {
          const impressions = calcImpressions(Number(value), channel.cpm);
          debouncedSetValue(
            `partialChannels[${index}].impressions`,
            impressions,
            setValue
          )();
        } else {
          setValue(`partialChannels[${index}].impressions`, 0);
        }
      },
    },
  ],
  cpc: [
    {
      value: 'clicks',
      disabled: true,
    },
    {
      value: 'cpc',
      onChange: (
        setValue: any,
        name: string,
        value: string,
        index: number,
        channel: any
      ) => {
        setValue(name, value);
        if (channel.cost > 0 && Number(value) > 0) {
          const clicks = calcClicks(channel.cost, Number(value));
          debouncedSetValue(
            `partialChannels[${index}].clicks`,
            clicks,
            setValue
          )();
        } else {
          setValue(`partialChannels[${index}].clicks`, 0);
        }
      },
    },
    {
      value: 'cost',
      onChange: (
        setValue: any,
        name: string,
        value: string,
        index: number,
        channel: any
      ) => {
        setValue(name, value);
        if (channel.cpc > 0 && Number(value) > 0) {
          const clicks = calcClicks(Number(value), channel.cpc);
          debouncedSetValue(
            `partialChannels[${index}].clicks`,
            clicks,
            setValue
          )();
        } else {
          setValue(`partialChannels[${index}].clicks`, 0);
        }
      },
    },
  ],
  'cpm-e': [
    {
      value: 'numberOfDays',
      onChange: (setValue: any, name: string, value: string) => {
        setValue(name, value);
      },
    },
    {
      value: 'cost',
      onChange: (setValue: any, name: string, value: string) => {
        setValue(name, value);
      },
    },
  ],
  ff: [
    {
      value: 'cost',
      onChange: (setValue: any, name: string, value: string) => {
        setValue(name, value);
      },
    },
  ],
};
