import { Typography } from '@mui/material';
import { EMPTY_VALUE } from 'shared/constants';
import { Currencies } from 'shared/types';
import { formatCurrency } from 'shared/utils';
import BudgetTypography from '../components/BudgetTypography/BudgetTypography';

export const renderBudgetCell = (
  value: number | null,
  rowColor: string,
  currency: Currencies,
  exchangeRate?: number
): JSX.Element | string => {
  const budget = formatCurrency(value, 'PLN');

  const exchangedBudget =
    exchangeRate && value
      ? formatCurrency(Math.round(value / exchangeRate), 'EUR')
      : null;
  return (
    <>
      <BudgetTypography color={rowColor} value={budget} />
      {currency !== 'PLN' && (
        <Typography
          color={rowColor}
          fontWeight={700}
          fontSize="0.8rem"
          textTransform="uppercase"
          letterSpacing={0.04}
        >
          {exchangedBudget ? `(${exchangedBudget})` : EMPTY_VALUE}
        </Typography>
      )}
    </>
  );
};
