import * as yup from 'yup';
import { useMemo } from 'react';

export default () => {
  return useMemo(() => {
    return yup.object({
      touchpoints: yup.array().required(),
      divisionName: yup.string().required(),
      campaignTypeName: yup.string().required(),
      campaignStartDate: yup.object().required(),
    });
  }, []);
};
