import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TotalBudgetWidget from 'shared/components/TotalBudgetWidget/TotalBudgetWidget';
import { Countries } from 'shared/enums';
import { Budget, useTotalBudgetStore } from 'store/totalBudgetStore';
import { shallow } from 'zustand/shallow';
import TotalBudget from './components/TotalBudget/TotalBudget';
import { TotalRow, Wrapper } from './styles';
import CountrySection from './components/CountrySection/CountrySection';

export function isBaltic(country: Countries): boolean {
  return [Countries.LITHUANIA, Countries.LATVIA, Countries.ESTONIA].includes(
    country
  );
}

function BalticCountries() {
  const { t } = useTranslation();
  const totalBudget = useTotalBudgetStore((state) => state, shallow);

  const totalBalticBudget = Object.entries(totalBudget).reduce(
    (acc, entry) => {
      const [country, budget] = entry as [Countries, Budget];
      const total: Budget = acc;
      if (isBaltic(country as Countries)) {
        total.total = (total.total ?? 0) + (budget.total ?? 0);
        total.mustHave = (total.mustHave ?? 0) + (budget.mustHave ?? 0);
        total.optional = (total.optional ?? 0) + (budget.optional ?? 0);
      }
      return total;
    },
    { total: 0, mustHave: 0, optional: 0 } as Budget
  );

  return (
    <Wrapper>
      <TotalBudgetWidget data={totalBudget} />
      <CountrySection country={Countries.LITHUANIA} />
      <CountrySection country={Countries.LATVIA} />
      <CountrySection country={Countries.ESTONIA} />
      <TotalRow>
        <Stack
          direction="row"
          alignItems="flex-start"
          height="fit-content"
          justifyContent="space-between"
          gap="1.5rem"
          sx={{ height: '100%' }}
        >
          <Typography
            fontWeight="700"
            textTransform="uppercase"
            color="textDark"
          >
            {t('balticsTotal')}
          </Typography>
          <TotalBudget budget={totalBalticBudget} />
        </Stack>
      </TotalRow>
    </Wrapper>
  );
}

export default BalticCountries;
