import { styled, Typography } from '@mui/material';

export const StyledHeader = styled(Typography)({
  fontWeight: 'bold',
  textTransform: 'uppercase',
  letterSpacing: '0.04em',
});

interface HeaderProps {
  title: string;
}

function Header({ title }: HeaderProps) {
  return (
    <StyledHeader variant="body2" color="secondary">
      {title}
    </StyledHeader>
  );
}

export default Header;
