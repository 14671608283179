import { Card, Table as MuiTable, TableBody, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Header from '../Header/Header';
import { renderData } from './renderers/dataRenderer';
import { renderTableHead } from './renderers/tableHeadRenderer';
import { HeaderWrapper, StyledCard, StyledTableContainer } from './styles';
import { TableProps } from './types';

function ParametersTable({ parameters, touchpoints, data }: TableProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <StyledCard>
      <HeaderWrapper>
        <Header title={t('calculator.campaignParameters')} />
      </HeaderWrapper>
      <Card>
        <StyledTableContainer>
          <MuiTable stickyHeader aria-label="sticky table" size="small">
            {renderTableHead(touchpoints, t)}
            <TableBody>
              {renderData({
                theme,
                parameters,
                touchpoints,
                data,
                t,
              })}
            </TableBody>
          </MuiTable>
        </StyledTableContainer>
      </Card>
    </StyledCard>
  );
}

export default ParametersTable;
