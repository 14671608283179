import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Auth0Provider } from '@auth0/auth0-react';
import { AuthProvider } from 'context/AuthContext';

import queryClient from 'config/queryClient';
import i18n from 'config/i18n';
import theme from 'config/theme';
import router from 'router';
import 'config/yup';
import './index.css';

import { StrictMode } from 'react';
import { QueryClientProvider } from 'react-query';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <QueryClientProvider client={queryClient}>
            <Auth0Provider
              domain={process.env.REACT_APP_AUTH0_DOMAIN}
              clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
              authorizationParams={{
                redirect_uri: window.location.origin,
              }}
            >
              <AuthProvider>
                <RouterProvider router={router} />
              </AuthProvider>
            </Auth0Provider>
          </QueryClientProvider>
        </LocalizationProvider>
      </I18nextProvider>
    </ThemeProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
