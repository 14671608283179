import { fetchData, postData } from '../utils';
import { ApiResponse } from '../types';

export const DIGITAL_FAIR_VALUE_KEY = 'digital-fair-value';

export const getDivisions = async () => {
  return fetchData<
    ApiResponse<{
      divisions: string[];
    }>
  >(`/${DIGITAL_FAIR_VALUE_KEY}/divisions`);
};

export const getBrands = async (divisions: string[]) => {
  return fetchData<
    ApiResponse<{
      brands: string[];
    }>
  >(`/${DIGITAL_FAIR_VALUE_KEY}/brands?divisions=${JSON.stringify(divisions)}`);
};

export const getPartners = async () => {
  return fetchData<
    ApiResponse<{
      partners: string[];
    }>
  >(`/${DIGITAL_FAIR_VALUE_KEY}/partners`);
};

export const getChannels = async (accessType: 'full' | 'partial') => {
  return fetchData<
    ApiResponse<{
      channels: Array<{ channel: string; benchmark: string }>;
    }>
  >(`/${DIGITAL_FAIR_VALUE_KEY}/channels?accessType=${accessType}`);
};

export const verifyDigitalFairValue = async (body: {
  currentStep: number;
  brandNames: string[];
  divisionNames: string[];
  partnerName: string;
  startDate: string;
  endDate: string;
  campaignPeriod: string;
  isDedIssued: boolean;
  isFullSalesDataReceived: boolean;
  dashboardExcel: string;
  isRoasConfirmed: boolean;
  fullAccessChannels?: Array<{
    id: string;
    channelName: string;
    dashboardLink: string;
    accessOwner: string;
    digitalConsultant: string;
  }>;
  partialAccessChannels?: Array<{
    id: string;
    channelName: string;
    impr?: number;
    cpm?: number;
    cost?: number;
    clicks?: number;
    cpc?: number;
    numberOfDays?: number;
  }>;
}) => {
  return postData<
    ApiResponse<{
      errors: Array<{
        rowId: string;
        channelName?: string;
        fields?: Array<string>;
      }>;
      isVerify: boolean;
      transactionId: string;
    }>
  >(`/${DIGITAL_FAIR_VALUE_KEY}/verify?step=${body.currentStep}`, body);
};
