import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Priority from 'shared/components/Priority/Priority';
import { formatCurrency, formatNumber } from 'shared/utils';

const COLUMN_ALIGN = 'right' as const;

export default () => {
  const { t } = useTranslation();
  return useMemo(
    () => [
      {
        key: 'touchpointName',
        label: t('calculator.table.columns.touchpoint'),
      },
      { key: 'touchpointFunnel', label: t('calculator.table.columns.phase') },
      {
        key: 'isMustHave',
        label: t('calculator.table.columns.priority'),
        width: '20rem',
        align: 'center' as const,
        renderer: (value: boolean) => <Priority isMustHave={value} />,
      },
      {
        key: 'budget',
        label: t('calculator.table.columns.budget'),
        dividerColumn: true,
        align: COLUMN_ALIGN,
        format: formatCurrency,
        width: '7rem',
      },
      {
        key: 'impressions',
        label: t('calculator.table.columns.impressions'),
        format: formatNumber,
        align: COLUMN_ALIGN,
        width: '7rem',
      },
      {
        key: 'reach',
        label: t('calculator.table.columns.reach'),
        format: formatNumber,
        align: COLUMN_ALIGN,
        width: '7rem',
      },
      {
        key: 'clicks',
        label: t('calculator.table.columns.clicks'),
        format: formatNumber,
        align: COLUMN_ALIGN,
        width: '7rem',
      },
      {
        key: 'videoViews',
        label: t('calculator.table.columns.videoViews'),
        format: formatNumber,
        align: COLUMN_ALIGN,
        width: '7rem',
      },
    ],
    [t]
  );
};
