import { Box, Button, styled } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

export const Wrapper = styled('div')({
  position: 'fixed',
  right: '0',
  zIndex: 999,
  height: '34.8rem',
  width: '23rem',
});

export const FloatingButton = styled(Button)({
  visibility: 'visible',
  width: '23rem',
  borderRadius: '10px 10px 0px 0px',
  padding: '0.3rem 1.5rem',
  justifyContent: 'space-between',
});

export const FloatingBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  height: '35rem',
  width: '23rem',
  visibility: 'visible',
  boxShadow: `-8px 0px 24px ${theme.palette.boxShadow.main}`,
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledDownloadIcon = styled(DownloadIcon)({
  color: 'white',
});
