import { TableRow, Theme, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import { formatNumber, getComment } from 'shared/utils';
import { EMPTY_VALUE } from 'shared/constants';
import { TFunction } from 'i18next';
import { omit } from 'lodash';
import { StyledRowCell } from '../styles';
import { TableProps } from '../types';
import { COMMENT_PADDING, PARAMETER_PADDING } from '../constants';

const touchpointRenderer = (
  touchpointValue: string | number | null,
  t: TFunction<'translation', undefined, 'translation'>
) => {
  if (touchpointValue === null) return EMPTY_VALUE;
  if (typeof touchpointValue === 'boolean') {
    return touchpointValue ? (
      <CheckIcon color="success" />
    ) : (
      <CloseIcon color="error" />
    );
  }
  if (typeof touchpointValue === 'number') {
    return <span>{formatNumber(touchpointValue)}</span>;
  }
  return <span>{t(`calculator.${touchpointValue}`)}</span>;
};

export const renderData = ({
  theme,
  touchpoints,
  parameters,
  data,
  t,
}: TableProps & {
  theme: Theme;
  t: TFunction<'translation', undefined, 'translation'>;
}) => {
  return data.map((cell, index) => {
    const rowColor =
      index % 2 === 0 ? theme.palette.tableBackground.main : 'white';

    const comment = getComment(Object.values(omit(cell, 'id')), t);

    return (
      <TableRow key={cell.id} sx={{ backgroundColor: rowColor }}>
        <StyledRowCell sx={{ padding: PARAMETER_PADDING }}>
          {parameters[index]}
        </StyledRowCell>
        {touchpoints.map((touchpoint) => (
          <StyledRowCell key={`${cell.id}${touchpoint}`} align="center">
            {touchpointRenderer(cell[touchpoint], t)}
          </StyledRowCell>
        ))}
        <StyledRowCell
          sx={{
            padding: COMMENT_PADDING,
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          {comment && <WarningIcon color="warning" fontSize="small" />}
          <Typography variant="body2">{comment || EMPTY_VALUE}</Typography>
        </StyledRowCell>
      </TableRow>
    );
  });
};
