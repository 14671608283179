import { Grid } from '@mui/material';
import TotalBudget from 'shared/components/TotalBudget/TotalBudget';
import { Budget } from 'store/totalBudgetStore';

export interface TotalBudgetProps {
  budget: Budget;
}

const firstRowProps = {
  variant: 'body1',
  fontWeight: '900',
  textTransform: 'uppercase',
  color: 'textDark.main',
} as const;

const secondRowProps = {
  variant: 'body2',
  fontWeight: '700',
  textTransform: 'uppercase',
} as const;

const thirdRowProps = {
  variant: 'body2',
  fontWeight: '500',
  textTransform: 'uppercase',
} as const;

function StyledTotalBudget({
  budget: { total, mustHave, optional },
}: TotalBudgetProps) {
  return (
    <Grid container item xs={12} style={{ flexBasis: '22rem', height: '90%' }}>
      <TotalBudget
        firstRowProps={firstRowProps}
        secondRowProps={secondRowProps}
        thirdRowProps={thirdRowProps}
        total={total}
        mustHave={mustHave}
        optional={optional}
        countryCurrency="EUR"
      />
    </Grid>
  );
}

export default StyledTotalBudget;
