import { cloneElement, ReactElement } from 'react';
import { IconButton, IconButtonProps } from '@mui/material';

type NavButtonProps = {
  icon: ReactElement;
  iconSize?: number;
} & IconButtonProps;

function NavButton({ icon, iconSize = 18, ...props }: NavButtonProps) {
  return (
    <IconButton {...props}>
      {cloneElement(icon, {
        sx: { width: `${iconSize}px`, height: `${iconSize}px` },
      })}
    </IconButton>
  );
}

export default NavButton;
