import { Box, Card, styled, TableCell, TableContainer } from '@mui/material';

export const StyledCard = styled(Card)({
  padding: '1.5rem',
});

export const StyledTableContainer = styled(TableContainer)({
  maxHeight: '40rem',
  position: 'relative',
});

export const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  padding: '0 1rem',
  height: '3.5rem',
  borderBottom: `1px solid ${theme.palette.border.main}`,
}));

export const StyledRowCell = styled(TableCell)({
  padding: '0 1rem',
  height: '3.5rem',
  borderBottom: 'none',
});

export const HeaderWrapper = styled(Box)({
  marginBottom: '1rem',
});
