import {
  Table as MuiTable,
  Card,
  TableBody,
  TableHead,
  useTheme,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Countries } from 'shared/enums';
import { CheckboxOnChangeEvent, TableRowOnClickEvent } from 'shared/types';
import { getRowIdsByPriority } from 'shared/utils';
import { useCountriesDataStore } from 'store/countriesDataStore';
import { shallow } from 'zustand/shallow';
import Header from '../Header/Header';
import TableFooter from './components/Footer/Footer';
import useFooterData from './hooks/useFooterData';
import { renderColumns } from './renderers/columnsRenderer';
import { renderRows } from './renderers/rowsRenderer';
import { HeaderWrapper, StyledCard, StyledTableContainer } from './styles';
import { TableProps } from './types';

function EstimatedBudgetsTable({
  columns,
  rows,
  country = Countries.POLAND,
  currency = 'PLN',
  exchangeRate,
}: TableProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const optionalRows = getRowIdsByPriority(rows, false);
  const shouldDisableCheckAll = isEmpty(optionalRows);

  const optionalIds = useCountriesDataStore(
    (state) => state[country].optionalIds,
    shallow
  );
  const updateOptionalIds = useCountriesDataStore(
    (state) => state[country].updateOptionalIds,
    shallow
  );

  const footerData = useFooterData({
    rows,
    optionalIds,
    currency,
    country,
  });

  const handleRowClick = useCallback(
    (e: CheckboxOnChangeEvent | TableRowOnClickEvent, rowId: string) => {
      e.preventDefault();
      const newOptionalIds = {
        ...optionalIds,
        [rowId]: !optionalIds[rowId],
      };
      updateOptionalIds(newOptionalIds);
    },
    [optionalIds, updateOptionalIds]
  );

  const handleToggleAll = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newOptionalIds = event.target.checked
        ? getRowIdsByPriority(rows, false, true)
        : getRowIdsByPriority(rows, false, false);

      updateOptionalIds(newOptionalIds);
    },
    [rows, updateOptionalIds]
  );

  const renderedColumns = useMemo(
    () =>
      renderColumns({
        handleToggleAll,
        shouldDisableCheckAll,
        columns,
      }),
    [columns, handleToggleAll, shouldDisableCheckAll]
  );

  const renderedRows = useMemo(
    () =>
      renderRows({
        theme,
        optionalIds,
        handleRowClick,
        rows,
        columns,
      }),
    [theme, optionalIds, handleRowClick, rows, columns]
  );

  return (
    <StyledCard>
      <HeaderWrapper>
        <Header title={t('calculator.estimatedBudgetPerTouchpoint')} />
      </HeaderWrapper>
      <Card>
        <StyledTableContainer>
          <MuiTable stickyHeader aria-label="sticky table" size="small">
            <TableHead>{renderedColumns}</TableHead>
            <TableBody>{renderedRows}</TableBody>
            <TableFooter
              data={footerData}
              currency={currency}
              exchangeRate={exchangeRate}
            />
          </MuiTable>
        </StyledTableContainer>
      </Card>
    </StyledCard>
  );
}

export default EstimatedBudgetsTable;
