import { Grid } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Header from 'shared/components/Header/Header';
import { yupResolver } from '@hookform/resolvers/yup';
import { shallow } from 'zustand/shallow';

import { useMutation } from 'react-query';
import { verifyTvFairValue } from 'api/services/tvFairValue';
import {
  CalculateButton,
  ClearButton,
} from 'shared/components/ParametersForm/styles';
import {
  useTvFairValueFormStore,
  TvFairValueFormData,
} from 'store/tvFairValueFormStore';
import moment from 'moment';
import { DATE_FORMATS } from 'shared/constants';
import { isEmpty } from 'lodash';
import { generateReport, getCheckDocStatus } from 'api/services/generatePdf';
import ResetStepperFormDialog from 'shared/components/ResetStepperFormModal/ResetStepperFormDialog';
import { HeaderWrapper, StyledCard } from './styles';
import { useValidationSchema as useValidationSchemaForWizard } from './validationSchemas';
import GenerateReportDialog from './components/GenerateReportDialog';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';

const DEFAULT_VALUES = {
  divisions: [],
  brands: [],
  partner: '',
  step: 0,
  currentStep: 0,
  acceptDedAndInvoiceIssuedGranularly: null,
  acceptReceivingFullSalesFromThePartner: null,
  dashboardExcel: '',
  period1_campaignPeriod: '',
  period1_campaignTG: '',
  period1_suggestedLorealTG: '',
  period1_copyLength: 0,
  period1_lorealCommLength: 0,
  period1_grpNatural: 0,

  period2_campaignPeriod: '',
  period2_campaignTG: '',
  period2_suggestedLorealTG: '',
  period2_copyLength: 0,
  period2_lorealCommLength: 0,
  period2_grpNatural: 0,

  campaignValue: 0,
  shouldIncludeSecondPeriod: false,
};

function FairValue() {
  const intervalId = useRef('');
  const lastFilePath = useRef('');
  const { t } = useTranslation();
  const validationSchema2 = useValidationSchemaForWizard();
  const [dialogStep, setDialogStep] = useState<
    'accept' | 'done' | 'loading' | 'reject' | ''
  >('');
  const [rejectText, setRejectText] = useState<React.ReactNode>('');
  const [resetDialog, setResetDialog] = useState({
    show: false,
  });
  const [currentStep, setCurrentStep] = useState(0);

  const handleCloseDialog = () => {
    setDialogStep('');
  };

  const handleCloseResetDialog = () => {
    setResetDialog({ show: false });
  };

  const formData2 = useTvFairValueFormStore((state) => state.formData, shallow);

  const resetForm2 = useTvFairValueFormStore(
    (state) => state.resetForm,
    shallow
  );

  const {
    data: verifyData,
    mutate: verify,
    isLoading,
  } = useMutation(verifyTvFairValue);

  const methods2 = useForm<TvFairValueFormData>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(validationSchema2),
    mode: 'onChange',
  });

  const {
    handleSubmit: handleSubmit2,
    reset: reset2,
    setValue: setValue2,
    getValues: getValues2,
    trigger: trigger2,
    setError,
  } = methods2;

  useEffect(() => {
    if (isLoading || currentStep <= 2) return;
    if (
      currentStep >= 4 &&
      verifyData?.data &&
      isEmpty(verifyData?.data.errors)
    ) {
      setDialogStep('accept');
    }
    if (currentStep >= 4 && !verifyData?.data.isVerify) {
      setRejectText(
        <>
          The data you supplied does not fulfill L`Oreal internal
          <br />
          requirements.
          <br />
          The Campaign value is incorrect.
        </>
      );
      setDialogStep('reject');
      setError(`campaignValue` as any, {
        type: 'custom',
        message: '',
      });
    }
  }, [currentStep, isLoading, t, verifyData?.data, verifyData?.data.errors]);

  useEffect(() => {
    Object.entries(formData2).forEach(([key, value]) => {
      setValue2(key as keyof typeof formData2, value);
    });
  }, [formData2, setValue2, trigger2]);

  const onReportDownload = () => {
    if (!lastFilePath.current) return;

    const anchor = document.createElement('a');
    anchor.href = lastFilePath.current;
    anchor.target = '_blank';
    anchor.download = 'report';
    anchor.rel = 'noreferrer';
    // Append the anchor to the body, trigger click, then remove it
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const onSubmit2 = async (data: TvFairValueFormData) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const values = getValues2();
    if (
      (values.step === 1 || values.step === 2) &&
      (values.acceptDedAndInvoiceIssuedGranularly === 'false' ||
        values.acceptReceivingFullSalesFromThePartner === 'false')
    ) {
      setDialogStep('reject');
      return;
    }

    const secondPeriod = data.shouldIncludeSecondPeriod
      ? [
          {
            id: '2',
            campaignMonth: data.period2_campaignPeriod,
            campaignMonthTranslation: t(
              `periods.${data.period2_campaignPeriod}`
            ),
            campaignTG: data.period2_campaignTG!,
            suggestedLorealTG: data.period2_suggestedLorealTG!,
            copyLength: data.period2_copyLength!,
            lorealCommLength: data.period2_lorealCommLength!,
            grpNatural: data.period2_grpNatural!,
          },
        ]
      : [];
    verify({
      brandNames: data.brands.map(({ title }) => title),
      divisionNames: data.divisions.map(({ title }) => title),
      partnerName: data.partner,
      isDedIssued: Boolean(data.acceptDedAndInvoiceIssuedGranularly),
      isFullSalesDataReceived: Boolean(
        data.acceptReceivingFullSalesFromThePartner
      ),
      dashboardExcel: data.dashboardExcel,
      periods: [
        {
          id: '1',
          campaignMonth: data.period1_campaignPeriod,
          campaignMonthTranslation: t(`periods.${data.period1_campaignPeriod}`),
          campaignTG: data.period1_campaignTG,
          suggestedLorealTG: data.period1_suggestedLorealTG,
          copyLength: data.period1_copyLength,
          lorealCommLength: data.period1_lorealCommLength,
          grpNatural: data.period1_grpNatural,
        },
        ...secondPeriod,
      ].filter(Boolean),
      campaignValue: data.campaignValue,
      currentStep: Math.min(4, currentStep + 1),
    });

    setCurrentStep((step) => step + 1);
    setValue2('step', currentStep + 1);
  };

  const onGeneratePdf = async () => {
    await generateReport({
      transactionId: verifyData!.data!.transactionId,
      type: 'tv-fair-value',
    });
    setDialogStep('loading');

    async function checkDocStatus() {
      const result = await getCheckDocStatus(verifyData!.data!.transactionId!);
      if (result?.data?.status === 'done') {
        clearInterval(intervalId.current);
        setDialogStep('done');
        lastFilePath.current = result.data.filePath;
      }
    }

    const id = setInterval(() => {
      checkDocStatus();
    }, 3000);
    intervalId.current = `${id}`;
  };

  const clearForm = () => {
    resetForm2();
    reset2(DEFAULT_VALUES);
    setCurrentStep(0);
  };

  const onError = (errors: any) => {
    console.error('Form submission failed');
    console.error(errors);
  };

  return (
    <StyledCard>
      {resetDialog.show && (
        <ResetStepperFormDialog
          open
          onClose={handleCloseResetDialog}
          onSuccess={clearForm}
        />
      )}
      {dialogStep && (
        <GenerateReportDialog
          open
          step={dialogStep}
          onClose={handleCloseDialog}
          onGeneratePdf={onGeneratePdf}
          onDownload={onReportDownload}
          rejectText={rejectText}
        />
      )}
      <HeaderWrapper>
        <Header title={t('digitalFairValue.generalInformation')} />
      </HeaderWrapper>
      <FormProvider {...methods2}>
        <form onSubmit={handleSubmit2(onSubmit2, onError)}>
          <Step1 />
          {currentStep >= 1 && <Step2 changeStep={setCurrentStep} />}
          {currentStep >= 2 && <Step3 changeStep={setCurrentStep} />}
          {currentStep >= 3 && <Step4 />}
          <Grid
            item
            xs={12}
            justifyContent="flex-start"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <ClearButton
              variant="text"
              onClick={() => {
                setResetDialog({
                  show: true,
                });
              }}
            >
              {t('clearForm')}
            </ClearButton>
            <CalculateButton type="submit" variant="contained">
              {t('next')}
            </CalculateButton>
          </Grid>
        </form>
      </FormProvider>
    </StyledCard>
  );
}

export default FairValue;
