import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps,
} from '@mui/material';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props extends SelectProps {
  options: string[];
  helperText?: string;
  error?: boolean;
  label: string;
  translateOptionKey?: string;
}

interface FormProps extends Props {
  name: string;
  control: unknown;
}

function Select({
  label,
  helperText,
  error,
  options,
  translateOptionKey,
  disabled,
  ...rest
}: Props) {
  const { t } = useTranslation();
  return (
    <FormControl fullWidth disabled={disabled} error={error}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          PaperProps: {
            style: {
              maxHeight: 600,
              overflowX: 'auto',
            },
          },
        }}
        {...rest}
        label={label}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {translateOptionKey ? t(`${translateOptionKey}.${option}`) : option}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

function FormSelect({ name, control, label, ...rest }: FormProps) {
  return (
    <Controller
      name={name}
      control={control as Control<FieldValues, unknown>}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Select
          label={label}
          value={value || ''}
          onChange={onChange}
          error={!!error}
          helperText={error?.message}
          {...rest}
        />
      )}
    />
  );
}

export { FormSelect, Select };
