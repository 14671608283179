/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as yup from 'yup';
import { benchmarks } from './benchmarks';

const fullDataAccessChannelSchema = yup.object().shape({
  channel: yup.string().required('Channel is required'),
  linkToAccountDashboard: yup
    .string()
    .url('Invalid URL')
    .required('Link to account dashboard is required'),
  accessOwner: yup
    .string()
    .required('Access owner is required')
    .test('is-email', 'Value must be a valid email', (value) => {
      if (!value) return false;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(value) || !value;
    }),
  digitalConsultant: yup
    .string()
    .required('Digital consultant is required')
    .test('is-email', 'Value must be a valid email', (value) => {
      if (!value) return false;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(value) || !value;
    }),
});

const withoutFullDataAccessChannelSchema = yup.object().shape({
  channel: yup.string().required('Channel is required'),
  benchmark: yup.string(),
  impressions: yup.number().when('benchmark', (value) => {
    // @ts-ignore
    if (!benchmarks[value[0]]?.some((x) => x.value === 'impressions')) {
      return yup.mixed().nullable();
    }
    return yup
      .number()
      .transform((v) => (Number.isNaN(v) ? undefined : v))
      .required('Impressions are required')
      .min(1, 'Impressions must be a positive number');
  }),
  cpm: yup.number().when('benchmark', (value) => {
    // @ts-ignore

    if (!benchmarks[value[0]]?.some((x) => x.value === 'cpm')) {
      return yup.mixed().nullable();
    }
    return yup
      .number()
      .transform((v) => (Number.isNaN(v) ? undefined : v))
      .required('CPM are required')
      .positive('CPM must be a positive number');
  }),
  cost: yup
    .number()
    .transform((v) => (Number.isNaN(v) ? undefined : v))
    .required('Cost are required')
    .positive('Cost must be a positive number'),
  numberOfDays: yup.number().when('benchmark', (value) => {
    // @ts-ignore
    if (!benchmarks[value[0]]?.some((x) => x.value === 'numberOfDays')) {
      return yup.mixed().nullable();
    }
    return yup
      .number()
      .transform((v) => (Number.isNaN(v) ? undefined : v))
      .required('Number of days are required')
      .min(1, 'Number of days must be a positive number');
  }),
  cpc: yup.number().when('benchmark', (value) => {
    // @ts-ignore
    if (!benchmarks[value[0]]?.some((x) => x.value === 'cpc')) {
      return yup.mixed().nullable();
    }
    return yup
      .number()
      .transform((v) => (Number.isNaN(v) ? undefined : v))
      .required('CPC are required')
      .positive('CPC must be a positive number');
  }),
  clicks: yup.number().when('benchmark', (value) => {
    // @ts-ignore
    if (!benchmarks[value[0]]?.some((x) => x.value === 'clicks')) {
      return yup.mixed().nullable();
    }
    return yup
      .number()
      .transform((v) => (Number.isNaN(v) ? undefined : v))
      .required('Clicks are required')
      .min(1, 'Clicks must be a positive number');
  }),
});

export const useValidationSchema = () => {
  return yup.object().shape({
    step: yup.number().optional(),
    divisions: yup.array().min(1, 'Please select at least one division'),
    brands: yup.array().min(1, 'Please select at least one brand'),
    partner: yup.string().required('Partner is required'),
    startDate: yup.date().required('Start date is required'),
    endDate: yup
      .date()
      .required('End date is required')
      .min(yup.ref('startDate'), 'End date cant be before start date'),
    acceptDedAndInvoiceIssuedGranularly: yup.boolean().when('step', (value) => {
      if (value[0] === 0) {
        return yup.boolean().nullable();
      }
      return yup.boolean().required('You have to choose whether you agree');
    }),
    acceptReceivingFullSalesFromThePartner: yup
      .boolean()
      .when('step', (value) => {
        if (value[0] <= 1) {
          return yup.boolean().nullable();
        }
        return yup.boolean().required('You have to choose whether you agree');
      }),
    dashboardExcel: yup
      .string()
      .nullable()
      .when(
        ['step', 'acceptReceivingFullSalesFromThePartner'],
        ([step, acceptReceivingFullSalesFromThePartner], schema) => {
          return step[0] <= 1 ||
            acceptReceivingFullSalesFromThePartner === 'false' ||
            !acceptReceivingFullSalesFromThePartner
            ? schema // Allow null if step is less than or equal to 2
            : schema.required('Dashboard Excel is required');
        }
      ),
    fullChannels: yup.array().when('step', (step, schema) => {
      return step[0] <= 2
        ? schema.nullable().notRequired() // Allow empty array or null if step is less than or equal to 3
        : schema.of(fullDataAccessChannelSchema);
      // .min(1, 'Please create at least one channel'); // Require at least one element otherwise
    }),
    partialChannels: yup.array().when('step', (step, schema) => {
      return step[0] <= 2
        ? schema.nullable().notRequired() // Allow empty array or null if step is less than or equal to 3
        : schema.of(withoutFullDataAccessChannelSchema);
      // .min(1, 'Please create at least one channel'); // Require at least one element otherwise
    }),
  });
};
