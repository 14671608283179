import { IconButton, Typography } from '@mui/material';
import { FloatingButton, StyledDownloadIcon, Wrapper } from './styles';

function DownloadFileWidget({
  title,
  fileUrl,
}: {
  title: string;
  fileUrl: string;
}) {
  const handleDownload = () => {
    const anchor = document.createElement('a');
    anchor.href = fileUrl;
    anchor.download = 'debrief';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };
  return (
    <Wrapper>
      <div>
        <FloatingButton
          variant="contained"
          disableRipple
          endIcon={
            <IconButton component="div" onClick={handleDownload}>
              <StyledDownloadIcon />
            </IconButton>
          }
        >
          <Typography
            color="white"
            fontWeight={900}
            textTransform="uppercase"
            variant="body2"
            style={{
              flexGrow: 1,
              marginRight: '0rem',
            }}
          >
            {title}
          </Typography>
        </FloatingButton>
      </div>
    </Wrapper>
  );
}

export default DownloadFileWidget;
