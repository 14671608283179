import { Autocomplete, TextField, Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { forwardRef } from 'react';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface MultiSelectAutocompleteFormProps {
  id: string;
  options: Array<{
    id: number | string;
    title: string;
  }>;
  initialOptions: Array<{
    id: number | string;
    title: string;
  }>;
  label: string;
  noOptionsText: string;
  limitTags: number;
  disableClearable: boolean;
  onChange: (event: any, value: any) => void;
  disabled: boolean;
  error: any;
  helperText: any;
  value: any;
  [key: string]: any;
}

const renderTags = (tagValue: any, getTagProps: any): any => {
  if (tagValue.length > 0) {
    return (
      <span className="custom-tag">
        <span className="custom-text">{tagValue[0].title}</span>
        {tagValue.length > 1 ? ` +${tagValue.length - 1}` : ''}
      </span>
    );
  }
  return null;
};

const MultiSelectAutocomplete = forwardRef(function MultiSelectAutocomplete(
  {
    id,
    options,
    initialOptions = [],
    label,
    noOptionsText,
    limitTags = 1,
    disableClearable = false,
    onChange,
    disabled = false,
    error,
    helperText,
    value,
  }: MultiSelectAutocompleteFormProps,
  ref
) {
  return (
    <Autocomplete
      ref={ref}
      multiple
      limitTags={limitTags}
      id={id}
      value={value}
      options={options}
      defaultValue={initialOptions}
      disabled={disabled}
      disableClearable={disableClearable}
      onChange={onChange}
      // eslint-disable-next-line @typescript-eslint/no-shadow
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disableCloseOnSelect
      noOptionsText={noOptionsText}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <TextField
          style={{ width: '100%' }}
          error={error}
          helperText={helperText}
          {...params}
          label={label}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      renderTags={renderTags}
      sx={{
        '& .MuiAutocomplete-tag': {
          bgcolor: 'transparent',
          color: 'black',
        },
        '& .custom-tag': {
          maxWidth: '80%',
          whiteSpace: 'nowrap',
        },
        '& .custom-text': {
          verticalAlign: 'bottom',
          marginRight: '0.2rem',
          maxWidth: '0%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        '& .MuiAutocomplete-inputRoot': {
          flexWrap: 'nowrap !important',
          maxWidth: '100%',
        },
      }}
    />
  );
});

export default MultiSelectAutocomplete;
