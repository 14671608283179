import { calculateCampaignParameters } from 'api/services/campaigns';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { Countries } from 'shared/enums';
import { useCountriesDataStore } from 'store/countriesDataStore';
import { shallow } from 'zustand/shallow';

export default (country: Countries) => {
  const {
    estimatedBudget,
    parameters,
    updateEstimatedBudget,
    updateParameters,
  } = useCountriesDataStore(
    (state) => ({
      estimatedBudget: state[country].estimatedBudget,
      parameters: state[country].parameters,
      updateEstimatedBudget: state[country].updateEstimatedBudget,
      updateParameters: state[country].updateParameters,
    }),
    shallow
  );

  const { data, mutate, isLoading } = useMutation(calculateCampaignParameters);

  useEffect(() => {
    if (!data) return;
    updateEstimatedBudget(data?.data.estimatedBudgets);
    updateParameters(data?.data.parametersCampaign);
  }, [data, updateEstimatedBudget, updateParameters]);

  return {
    estimatedBudget,
    parameters,
    mutate,
    isFetchingData: isLoading,
  };
};
