import NavTabs from 'shared/components/NavTabs/NavTabs';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import routes from 'router/routes';
import { AccessRoles } from 'shared/auth';
import { isAllowed, useAuthContext } from 'context/AuthContext';
import { useEffect, useMemo } from 'react';
import { Paper, Stack, styled } from '@mui/material';
import { Path } from 'shared/types';

const Wrapper = styled(Stack)(({ theme }) => ({
  minHeight: 'calc(100% - 54px)',
  backgroundColor: theme.palette.lightGrey.main,
}));
const Content = styled(Paper)({
  borderRadius: 0,
  display: 'flex',
  padding: '1rem 2rem',
  flexDirection: 'column',
  margin: '0 2rem',
  flexGrow: 1,
});

function Calculator(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { userRoles } = useAuthContext();
  const { t } = useTranslation();

  const filteredPaths = useMemo<Path[]>(
    () =>
      [
        {
          to: routes.poland,
          label: t('countries.PL'),
          show: isAllowed(
            [AccessRoles.FULL_ACCESS, AccessRoles.ONLINE_CAMPAIGNS],
            userRoles
          ),
        },
        {
          to: routes.balticCountries,
          label: t('balticCountries'),
          show: isAllowed(
            [AccessRoles.FULL_ACCESS, AccessRoles.ONLINE_CAMPAIGNS],
            userRoles
          ),
        },
        {
          to: routes.fairValue,
          label: t('calculator.fairValue'),
          show: isAllowed(
            [AccessRoles.FULL_ACCESS, AccessRoles.FAIR_VALUE],
            userRoles
          ),
        },
        {
          to: routes.digitalFairValue,
          label: t('calculator.digitalFairValue'),
          show: isAllowed(
            [AccessRoles.FULL_ACCESS, AccessRoles.DIGITAL_FAIR_VALUE],
            userRoles
          ),
        },
      ].filter(({ show }) => show),
    [t, userRoles]
  );

  useEffect(() => {
    const currentPath = location.pathname;
    const currentPathIndex = filteredPaths.findIndex(
      ({ to }) => `/${to}` === currentPath
    );

    if (currentPathIndex === -1) {
      const firstPath = filteredPaths[0];
      if (firstPath) {
        navigate(firstPath.to);
      }
    }
  }, [filteredPaths, navigate, location]);

  return (
    <Wrapper>
      <Content>
        <NavTabs paths={filteredPaths} />
        <Stack flexGrow={1}>
          <Outlet />
        </Stack>
      </Content>
    </Wrapper>
  );
}

export default Calculator;
