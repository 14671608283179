import { Grid, Typography } from '@mui/material';
import { CONFIG_KEY, getExchangeRate } from 'api/services/config';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { EMPTY_VALUE } from 'shared/constants';
import { Currencies } from 'shared/types';
import { formatCurrency } from 'shared/utils';

interface TotalBudgetProps {
  firstRowProps: Record<string, unknown>;
  secondRowProps: Record<string, unknown>;
  thirdRowProps: Record<string, unknown>;
  total?: number | null;
  mustHave?: number | null;
  optional?: number | null;
  countryCurrency?: Currencies;
}

function TotalBudget({
  firstRowProps,
  secondRowProps,
  thirdRowProps,
  total,
  mustHave,
  optional,
  countryCurrency = 'PLN',
}: TotalBudgetProps) {
  const { t } = useTranslation();
  const { data } = useQuery(CONFIG_KEY, getExchangeRate);

  const exchangeRate = data?.data?.exchange_rate_eurpln;
  const exchangedTotal =
    exchangeRate && total
      ? formatCurrency(Math.round(total / exchangeRate), 'EUR')
      : null;
  return (
    <>
      <Grid item xs={6} sx={{ textAlignLast: 'left' }}>
        <Typography {...firstRowProps}>{t('total')}:</Typography>
      </Grid>
      <Grid item xs={6} sx={{ textAlignLast: 'right' }}>
        <Typography {...firstRowProps}>
          {formatCurrency(total, 'PLN')}
        </Typography>
        {countryCurrency !== 'PLN' && total !== 0 && (
          <Typography {...firstRowProps} fontSize="0.8rem">
            {exchangedTotal ? `(${exchangedTotal})` : EMPTY_VALUE}
          </Typography>
        )}
      </Grid>
      <Grid item xs={6} sx={{ textAlignLast: 'left' }}>
        <Typography {...secondRowProps}>{t('budgetMustHave')}:</Typography>
      </Grid>
      <Grid item xs={6} sx={{ textAlignLast: 'right' }}>
        <Typography {...secondRowProps} color="textDark.main" fontSize="0.8rem">
          {formatCurrency(mustHave, 'PLN')}
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ textAlignLast: 'left' }}>
        <Typography {...thirdRowProps}>{t('budgetOptional')}:</Typography>
      </Grid>
      <Grid item xs={6} sx={{ textAlignLast: 'right' }}>
        <Typography {...thirdRowProps} fontSize="0.8rem">
          {formatCurrency(optional, 'PLN')}
        </Typography>
      </Grid>
    </>
  );
}

export default TotalBudget;
