import { User } from '@auth0/auth0-react';

export enum AccessRoles {
  ONLINE_CAMPAIGNS = 'Online-Campaigns',
  FAIR_VALUE = 'Fair-Value',
  FULL_ACCESS = 'Full-Access',
  DIGITAL_FAIR_VALUE = 'Digital-Fair-Value',
}

export type MediaCalculatorUser = User & {
  'media-calculator/roles': AccessRoles[];
};
