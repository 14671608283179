import { TableCellProps, Typography, TypographyProps } from '@mui/material';
import { CSSProperties } from 'react';

function BudgetTypography({
  value,
  color,
  align = 'right',
  style,
  ...props
}: TypographyProps & {
  value: string | number;
  color: string;
  align?: TableCellProps['align'];
  style?: CSSProperties;
}) {
  return (
    <Typography
      color={color}
      fontWeight={700}
      fontSize="0.9rem"
      textTransform="uppercase"
      letterSpacing={0.04}
      align={align}
      style={style}
      {...props}
    >
      {value}
    </Typography>
  );
}
export default BudgetTypography;
