import {
  Box,
  Button,
  Card,
  FormHelperText,
  Grid,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

export const StyledCard = styled(Card)({
  padding: '1.5rem',
});

export const CampaignDatePicker = styled(DatePicker)({
  width: '100%',
});

export const FirstRow = styled(Grid)({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1.5fr',
  gap: '2rem',
  marginBottom: '2rem',
});

export const SecondRow = styled(Grid)({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1.5fr',
  gap: '2rem',
  marginBottom: '1rem',
});

export const ThirdRow = styled(Grid)({
  gap: '2rem',
  marginBottom: '1rem',
});

export const TouchpointsHeader = styled(Typography)({
  marginBottom: '1rem',
});

export const ClearButton = styled(Button)({
  width: 'fit-content',
  marginRight: '2.5rem',
});

export const CalculateButton = styled(Button)({
  width: '7rem',
});

export const Buttons = styled(Grid)({
  textAlignLast: 'right',
  alignSelf: 'center',
});

export const BudgetHelperText = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.primary.main,
  paddingLeft: '1rem',
}));

export const StyledTooltip = styled(Tooltip)({
  margin: '0 0.3rem',
});

export const HeaderWrapper = styled(Box)({
  marginBottom: '2rem',
});
