import { CircularProgress, styled } from '@mui/material';

export const LoadingPageWrapper = styled('div')({
  display: 'flex',
  minHeight: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
});

export default function LoadingPage() {
  return (
    <LoadingPageWrapper>
      <CircularProgress />
    </LoadingPageWrapper>
  );
}
