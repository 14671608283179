import { Box, styled, Typography, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Avatar from '@mui/material/Avatar';
import { useTranslation } from 'react-i18next';
import WarningIcon from '@mui/icons-material/Warning';
import oneNumber from 'assets/imgs/one_circle.svg';
import twoNumber from 'assets/imgs/two_circle.svg';
import threeNumber from 'assets/imgs/three_circle.svg';
import fourNumber from 'assets/imgs/four_circle.svg';
import fiveNumber from 'assets/imgs/five_circle.svg';
import sixNumber from 'assets/imgs/six_circle.svg';
import Header from 'shared/components/Header/Header';
import { StyledCard } from '../../styles';

const StyledStepText = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.textDark.main}`,
  fontSize: '14px',
  fontWeight: 600,
  marginTop: '17px',
  lineHeight: '30px',
  position: 'relative',
  top: '-40px',
}));

const StyledNumberImg = styled(Avatar)({
  width: '27px',
  height: '30px',
  position: 'absolute',
  top: '0',
  left: '0',
  transform: 'translateX(-55%) translateY(-130%)',
});

const StyledArrow = styled(ExpandMoreIcon)(({ theme }) => ({
  position: 'absolute',
  bottom: '0',
  left: '0',
  transform: 'translateX(-55%) translateY(45%)',
  color: `${theme.palette.lorealBlue.main}`,
}));

const StyledBoxWithLeftBorder = styled(Box)(({ theme }) => ({
  width: '75%',
  margin: '55px auto 0',
  position: 'relative',
  paddingLeft: '46px',
  borderLeft: `2px solid ${theme.palette.lorealBlue.main}`,
}));

function InstructionContent() {
  const theme = useTheme();
  const { t } = useTranslation();
  const instruction = 'modal.instruction';

  return (
    <>
      <StyledCard>
        <Header title={t(`${instruction}.generalInformation`)} />
        <StyledStepText
          sx={{
            top: '0',
            lineHeight: '24px',
          }}
        >
          {t(`${instruction}.generalTextTop`)}
        </StyledStepText>
        <ul>
          <li>{t(`${instruction}.generalLiItem1`)}</li>
          <li>{t(`${instruction}.generalLiItem2`)}</li>
        </ul>
        <StyledStepText
          sx={{
            top: '0',
            lineHeight: '24px',
          }}
        >
          {t(`${instruction}.generalTextBottom`)}
        </StyledStepText>
      </StyledCard>
      <StyledCard>
        <Header title={t(`${instruction}.stepByStep`)} />
        {/* Step 1 */}
        <StyledBoxWithLeftBorder>
          <StyledStepText>{t(`${instruction}.step1Text`)}</StyledStepText>
          <StyledArrow />
          <StyledNumberImg alt="number" src={oneNumber} />
        </StyledBoxWithLeftBorder>
        {/* Step 2 */}
        <StyledBoxWithLeftBorder>
          <StyledStepText>{t(`${instruction}.step2TextTop`)}</StyledStepText>
          <Box sx={{ display: 'flex', alignItems: 'start', marginTop: '30px' }}>
            <WarningIcon
              sx={{
                color: `${theme.palette.warning.main}`,
                marginRight: '20px',
                width: '22px',
                position: 'relative',
                top: '-20px',
              }}
            />
            <StyledStepText
              sx={{
                fontWeight: 700,
              }}
            >
              {t(`${instruction}.step2Alert`)}
            </StyledStepText>
          </Box>
          <StyledStepText
            sx={{
              marginTop: '40px',
            }}
          >
            {t(`${instruction}.step2TextBottom1`)}
            <br />
            {t(`${instruction}.step2TextBottom2`)}
            <br />
            {t(`${instruction}.step2TextBottom3`)}
          </StyledStepText>
          <StyledArrow />
          <StyledNumberImg alt="number" src={twoNumber} />
        </StyledBoxWithLeftBorder>
        {/* Step 3 */}
        <StyledBoxWithLeftBorder>
          <StyledStepText>
            {t(`${instruction}.step3TextTop`)}
            <span style={{ color: `${theme.palette.lorealPurple.main}` }}>
              {t(`${instruction}.stepTextPurple`)}
            </span>
            {t(`${instruction}.step3Separator`)}
            <span style={{ color: `${theme.palette.lorealBlue.main}` }}>
              {t(`${instruction}.stepTextBlue`)}
            </span>
            {t(`${instruction}.step3TextBottom`)}
          </StyledStepText>
          <StyledArrow />
          <StyledNumberImg alt="number" src={threeNumber} />
        </StyledBoxWithLeftBorder>
        {/* Step 4 */}
        <StyledBoxWithLeftBorder>
          <StyledStepText>
            {t(`${instruction}.step4TextTop`)}
            <br />
            {t(`${instruction}.step4TextBottom`)}
          </StyledStepText>
          <StyledArrow />
          <StyledNumberImg alt="number" src={fourNumber} />
        </StyledBoxWithLeftBorder>
        {/* Step 5 */}
        <StyledBoxWithLeftBorder>
          <StyledStepText>{t(`${instruction}.step5Text`)}</StyledStepText>
          <StyledArrow />
          <StyledNumberImg alt="number" src={fiveNumber} />
        </StyledBoxWithLeftBorder>
        {/* Step 6 */}
        <StyledBoxWithLeftBorder
          sx={{
            borderLeft: 'none',
          }}
        >
          <StyledStepText>
            {t(`${instruction}.step6Text`)}
            <span style={{ color: `${theme.palette.lorealPurple.main}` }}>
              {t(`${instruction}.stepTextPurple`)}
            </span>
            .
          </StyledStepText>
          <StyledNumberImg alt="number" src={sixNumber} />
        </StyledBoxWithLeftBorder>
      </StyledCard>
    </>
  );
}

export default InstructionContent;
