import { FooterData, FooterRow } from './types';

export const footerRow: FooterRow = {
  budget: null,
  impressions: null,
  reach: null,
  clicks: null,
  videoViews: null,
};
export const defaultData: FooterData = {
  budgetMustHave: footerRow,
  optionalTouchpoints: footerRow,
  totalMedia: footerRow,
  estimatedCosts: footerRow,
};
