import { createTheme } from '@mui/material/styles';

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#2F80ED',
      contrastText: '#fff',
    },
    primaryLight: {
      main: 'rgba(68, 126, 235, 0.3)',
      contrastText: '#fff',
    },
    secondary: {
      main: '#9B51E0',
      contrastText: '#000',
    },
    lorealPurple: {
      main: '#8043B9',
      contrastText: '#fff',
    },
    lorealBlue: {
      main: '#2D9CDB',
      contrastText: '#000',
    },
    success: {
      main: '#27AE60',
      contrastText: '#000',
    },
    error: {
      main: '#EB5757',
      contrastText: '#000',
    },
    warning: {
      main: '#F2994A',
      contrastText: '#000',
    },
    lightGrey: {
      main: '#e5e5e5',
      contrastText: '#000',
    },

    border: {
      main: '#c7d9f9',
      contrastText: '#000',
    },
    borderDark: {
      main: '#7ba5f1',
      contrastText: '#000',
    },
    textLight: {
      main: '#525F81',
      contrastText: '#fff',
    },
    textDark: {
      main: '#3B455E',
      contrastText: '#fff',
    },
    totalBackground: {
      main: '#DDE9F9',
      contrastText: '#000',
    },
    tableBackground: {
      main: '#F2F8FF',
      contrastText: '#000',
    },
    boxShadow: {
      main: 'rgba(82,95,129,0.35)',
      contrastText: '#fff',
    },
  },
  typography: (palette) => ({
    fontFamily: 'Lato, sans-serif',
    allVariants: {
      color: palette.textLight.main,
    },
    button: {
      textTransform: 'none',
    },
  }),
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiInputBase-root': {
            // maxWidth: '22vw',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: ({ theme }) => ({
          height: '0.2rem',
          color: theme.palette.primary.main,
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          letterSpacing: '0.04rem',
          opacity: 1,
          fontWeight: '700',
          '&.Mui-selected': {
            color: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: ({ theme }) => ({
          fontWeight: 700,
          color: theme.palette.primary.contrastText,
          background: theme.palette.primary.main,
          boxShadow: `0px 4px 9px ${theme.palette.boxShadow.main}`,
          borderRadius: '30px',
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.textDark.main,
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.primaryLight.main}`,
            cursor: 'not-allowed',
            pointerEvents: 'all !important',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: 5,
            borderColor: theme.palette.borderDark.main,
          },
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: ({ theme }) => ({
          borderColor: theme.palette.primary.main,
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          border: `1px solid ${theme.palette.border.main}`,
          borderRadius: '10px',
          boxShadow: 'none',
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.textLight.main,
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.tableBackground.main,
        }),
        content: {
          height: '7rem',
          padding: '0rem 1rem',
          justifyContent: 'space-between',
          '&.Mui-expanded': {
            margin: '12px 0',
            padding: '0rem 1rem',
          },
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.MuiInputLabel-root': {
            maxWidth: 'calc(100% - 3rem)',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.MuiFormHelperText-root': {
            maxWidth: 'calc(100% - 3rem)',
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiSlider-valueLabel': {
            fontSize: 12,
            padding: '2px',
            top: 0,
            backgroundColor: 'unset',
            color: theme.palette.text.primary,
          },
        }),
        mark: {
          width: 1,
        },
        markLabel: {
          fontSize: 12,
          // transform: 'translateX(-100%)',
          transform: 'translateX(-75%)',
          '&[data-index="0"]': {
            transform: 'translateX(-15%)',
            // transform: 'none',
          },
        },
      },
    },
  },
});

declare module '@mui/material/styles' {
  interface Palette {
    lightGrey: Palette['primary'];
    border: Palette['primary'];
    textLight: Palette['primary'];
    textDark: Palette['primary'];
    tableBackground: Palette['primary'];
    lorealPurple: Palette['primary'];
    lorealBlue: Palette['primary'];
    totalBackground: Palette['primary'];
    boxShadow: Palette['primary'];
    borderDark: Palette['primary'];
    primaryLight: Palette['primary'];
  }

  interface PaletteOptions {
    lightGrey: PaletteOptions['primary'];
    border: PaletteOptions['primary'];
    textLight: PaletteOptions['primary'];
    textDark: PaletteOptions['primary'];
    tableBackground: PaletteOptions['primary'];
    lorealPurple: PaletteOptions['primary'];
    lorealBlue: PaletteOptions['primary'];
    totalBackground: PaletteOptions['primary'];
    boxShadow: PaletteOptions['primary'];
    borderDark: PaletteOptions['primary'];
    primaryLight: PaletteOptions['primary'];
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    textDark: true;
    textLight: true;
  }
}

export default customTheme;
