import { Grid, SelectChangeEvent, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FormSelect, Select } from 'shared/components/Select/Select';

import {
  FAIR_VALUE_KEY,
  getCampaignTG,
  getCopyLength,
  getSuggestedLorealTG,
} from 'api/services/fairValue';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { getNextPeriod } from 'shared/utils';
import { useTvFairValueFormStore } from 'store/tvFairValueFormStore';
import { ChangeEvent, useEffect } from 'react';
import { PERIODS } from 'shared/constants';
import { HelperText } from '../../styles';

interface PeriodRowProps {
  period: 'period1' | 'period2';
  onChange: (
    event:
      | SelectChangeEvent<unknown>
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string
  ) => void;
}

function PeriodRow({ period, onChange }: PeriodRowProps) {
  const { t } = useTranslation();
  const { watch, control, setValue } = useFormContext();
  const updateForm = useTvFairValueFormStore((state) => state.updateForm);
  const values = watch() as any;
  const { data: responseCampaignTG } = useQuery(
    [FAIR_VALUE_KEY, 'target-groups'],
    getCampaignTG
  );

  const isFirstRow = period === 'period1';
  const isSecondRow = period === 'period2';

  const { data: responseSuggestedLorealTG } = useQuery({
    queryKey: [
      FAIR_VALUE_KEY,
      'referring-target-groups',
      values.period1_campaignTG,
    ],
    queryFn: () => getSuggestedLorealTG(values.period1_campaignTG),
    enabled: !!values.period1_campaignTG,
  });

  const { data: responseCopyLength } = useQuery(
    [FAIR_VALUE_KEY, 'copy-lengths'],
    getCopyLength
  );

  useEffect(() => {
    if (!responseSuggestedLorealTG?.data.suggestedReferringGroups) return;
    setValue(
      `${period}_suggestedLorealTG`,
      responseSuggestedLorealTG?.data.suggestedReferringGroups.find(
        (sTg) => sTg.suggestedGroup
      )?.targetGroupName || ''
    );
  }, [
    period,
    responseSuggestedLorealTG?.data.suggestedReferringGroups,
    setValue,
  ]);

  const shouldDisableSecondPeriod =
    !values.shouldIncludeSecondPeriod && isSecondRow;

  const disabledProps = (condition: boolean) => ({
    disabled: condition,
  });

  return (
    <>
      <Grid item xs={1.4}>
        <Controller
          name={`${period}_campaignPeriod`}
          control={control}
          render={({ field: { value }, fieldState: { error } }) => (
            <Select
              fullWidth
              translateOptionKey="periods"
              label={t('calculator.campaignPeriod')}
              options={PERIODS}
              renderValue={(option) => t(`periods.${option}`)}
              value={value || ''}
              onChange={(event) => {
                onChange(event, `${period}_campaignPeriod`);
                updateForm({
                  ...values,
                  period1_campaignPeriod: event.target.value as string,
                  period2_campaignPeriod: getNextPeriod(
                    event.target.value as string
                  ),
                });
              }}
              error={!!error}
              helperText={error?.message}
              {...disabledProps(shouldDisableSecondPeriod)}
            />
          )}
        />
      </Grid>
      <Grid item xs={2.2}>
        <FormSelect
          fullWidth
          name={`${period}_campaignTG`}
          control={control}
          onChange={(event) => {
            onChange(event, `${period}_campaignTG`);
            updateForm({
              ...values,
              period1_campaignTG: event.target.value as string,
              period2_campaignTG: event.target.value as string,
            });
          }}
          options={responseCampaignTG?.data.campaignTargetGroups || []}
          label={t('calculator.campaignTg')}
          {...disabledProps(shouldDisableSecondPeriod)}
        />
      </Grid>
      <Grid item xs={2.2}>
        <FormSelect
          fullWidth
          name={`${period}_suggestedLorealTG`}
          control={control}
          onChange={(event) => {
            onChange(event, `${period}_suggestedLorealTG`);
            updateForm({
              ...values,
              period1_suggestedLorealTG: event.target.value as string,
              period2_suggestedLorealTG: event.target.value as string,
            });
          }}
          options={
            responseSuggestedLorealTG?.data.suggestedReferringGroups.map(
              (sTg) => sTg.targetGroupName
            ) || []
          }
          label={t('calculator.suggestedReferringLorealTg')}
          {...disabledProps(
            (isFirstRow && !values.period1_campaignTG) ||
              shouldDisableSecondPeriod
          )}
        />
        {!isSecondRow && <HelperText>{t('canBeModified')}</HelperText>}
      </Grid>
      <Grid item xs={1.4}>
        <FormSelect
          fullWidth
          name={`${period}_copyLength`}
          control={control}
          onChange={(event) => onChange(event, `${period}_copyLength`)}
          options={responseCopyLength?.data.copyLength || []}
          label={t('calculator.copyLength', { metric: 'sec' })}
          {...disabledProps(shouldDisableSecondPeriod)}
        />
      </Grid>
      <Grid item xs>
        <Controller
          name={`${period}_lorealCommLength`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              type="number"
              label={t('calculator.lorealCommLength', { metric: 'sec' })}
              {...field}
              value={field.value}
              error={!!error}
              helperText={error?.message}
              onChange={(event) =>
                onChange(event, `${period}_lorealCommLength`)
              }
              {...disabledProps(shouldDisableSecondPeriod)}
            />
          )}
        />
      </Grid>
      <Grid item xs={1.7}>
        <Controller
          name={`${period}_grpNatural`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              type="number"
              label={t('calculator.GRPNatural')}
              {...field}
              value={field.value}
              error={!!error}
              helperText={error?.message}
              onChange={(event) => onChange(event, `${period}_grpNatural`)}
              {...disabledProps(shouldDisableSecondPeriod)}
            />
          )}
        />
      </Grid>
    </>
  );
}

export default PeriodRow;
