import { useTranslation } from 'react-i18next';
import { TableRow } from '@mui/material';
import { formatNumber } from 'shared/utils';
import { Currencies } from 'shared/types';
import { EMPTY_VALUE } from 'shared/constants';
import {
  FooterDivider,
  FooterLabelCol,
  FooterTableCell,
  StyledFooter,
} from './styles';
import { StyledDivider, StyledRowCell } from '../../styles';
import { FooterData } from '../../types';
import { renderLabelCell } from './renderers/labelCellRenderer';
import BudgetTypography from './components/BudgetTypography/BudgetTypography';
import { renderBudgetCell } from './renderers/budgetCellRenderer';

const FOOTER_HEIGHT = 10;
const CELL_HEIGHT = FOOTER_HEIGHT / 4;

type Key = keyof FooterData;
interface RowColorMap {
  [key: number]: string;
  default: string;
}
interface TableFooterProps {
  data: FooterData;
  currency: Currencies;
  exchangeRate?: number;
}
const rowColorMap: RowColorMap = {
  0: 'lorealPurple.main',
  1: 'lorealBlue.main',
  2: 'black',
  default: 'textDark.light',
};

const paddingMap = {
  0: '1rem 4rem 1rem 0rem',
  default: '0rem 4rem 0rem 0rem',
};

const budgetPaddingMap = {
  0: '1rem 1rem 0rem 0rem',
  default: '0rem 1rem',
};

function TableFooter({ data, currency, exchangeRate }: TableFooterProps) {
  const { t } = useTranslation();
  return (
    <StyledFooter sx={{ height: `${FOOTER_HEIGHT}rem` }}>
      <TableRow>
        <FooterDivider colSpan={100} />
      </TableRow>
      {Object.entries(data).map(([key, value], index) => {
        const isLastRow = index === Object.keys(data).length - 1;
        const isFirstRow = index === 0;

        const rowColor = rowColorMap[index] || rowColorMap.default;
        const dividerHeight = `${
          CELL_HEIGHT + (isLastRow || isFirstRow ? 1 : 0)
        }rem`;
        const labelCellStyles = {
          height: `${CELL_HEIGHT}rem`,
          padding: isLastRow
            ? '0rem 4rem 1rem 0rem'
            : paddingMap[isFirstRow ? 0 : 'default'],
        };
        const budgetCellStyles = {
          height: `${CELL_HEIGHT}rem`,
          padding: isLastRow
            ? '0rem 1rem 1rem 0rem'
            : budgetPaddingMap[isFirstRow ? 0 : 'default'],
        };

        const { budget, ...metrics } = value;

        return (
          <TableRow key={key}>
            <FooterLabelCol colSpan={3} />
            {renderLabelCell(
              key as Key,
              rowColor,
              labelCellStyles,
              t,
              exchangeRate
            )}
            <FooterTableCell sx={budgetCellStyles} align="right">
              {renderBudgetCell(budget, rowColor, currency, exchangeRate)}
            </FooterTableCell>
            <StyledRowCell sx={{ padding: 0, height: `${CELL_HEIGHT}rem` }}>
              <StyledDivider
                sx={{ height: dividerHeight }}
                orientation="vertical"
              />
            </StyledRowCell>
            {Object.entries(metrics).map(([metricKey, metric]) => (
              <FooterTableCell
                key={metricKey}
                sx={budgetCellStyles}
                align="right"
              >
                {metricKey !== 'reach' ? (
                  <BudgetTypography
                    color={rowColor}
                    value={formatNumber(metric)}
                  />
                ) : (
                  EMPTY_VALUE
                )}
              </FooterTableCell>
            ))}
          </TableRow>
        );
      })}
    </StyledFooter>
  );
}

export default TableFooter;
