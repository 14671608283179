import { CircularProgress, Stack, styled } from '@mui/material';
import { CONFIG_KEY, getExchangeRate } from 'api/services/config';
import { isEmpty } from 'lodash';
import { Moment } from 'moment';
import { useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useQuery } from 'react-query';
import EstimatedBudgetsTable from 'shared/components/EstimatedBudgetsTable/EstimatedBudgetsTable';
import ParametersForm from 'shared/components/ParametersForm/ParametersForm';
import ParametersTable from 'shared/components/ParametersTable/ParametersTable';
import { DATE_FORMATS, parametersBaltic } from 'shared/constants';
import { Countries } from 'shared/enums';
import useEstimatedBudgetColumns from 'shared/hooks/useEstimatedBudgetColumns';
import { Touchpoints } from 'shared/types';
import { ParametersFormData } from 'store/countriesDataStore';
import { useTotalBudgetStore } from 'store/totalBudgetStore';
import { shallow } from 'zustand/shallow';
import { LoaderWrapper } from 'shared/styles';
import useTranslatedParameters from 'shared/hooks/useTranslatedParameters';
import useCountryData from '../../hooks/useCountryData';
import CountryAccordion from '../CountryAccordion/CountryAccordion';

const touchpoints: Touchpoints[] = ['youtube', 'meta'];

function handleSubmitParametersCampaign(
  values: FieldValues,
  countryISOCode: Countries,
  calculateCampaignParameters: ({
    body,
    countryISOCode,
  }: {
    body: Omit<ParametersFormData, 'campaignStartDate'> & {
      campaignStartDate: string;
    };
    countryISOCode: Countries;
  }) => void
) {
  calculateCampaignParameters({
    body: {
      ...(values as ParametersFormData),
      campaignBumper: values.campaignBumper / 100,
      campaignDuration: Number(values.campaignDuration),
      campaignStartDate: (values.campaignStartDate as Moment).format(
        DATE_FORMATS.BY_HYPHEN
      ),
    },
    countryISOCode,
  });
}

const ChildrenWrapper = styled(Stack)({
  flexGrow: 1,
  margin: 0,
  padding: '2rem 1rem',
  gap: '2rem',
});

interface CountrySectionProps {
  country: Countries;
}

function CountrySection({ country }: CountrySectionProps) {
  const { data: exchangeRateResponse } = useQuery(CONFIG_KEY, getExchangeRate);
  const totalBudget = useTotalBudgetStore((state) => state[country], shallow);
  const estimatedBudgetTableColumns = useEstimatedBudgetColumns();
  const translatedParameters = useTranslatedParameters(parametersBaltic);
  const [expanded, setExpanded] = useState<string | false>('');

  const {
    isFetchingData,
    estimatedBudget,
    parameters: parametersData,
    mutate: calculateCampaignParameters,
  } = useCountryData(country);

  const toggleExpanded =
    (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <CountryAccordion
      country={country}
      expanded={expanded}
      onToggleExpanded={toggleExpanded}
      budgetTotal={totalBudget}
    >
      <ChildrenWrapper>
        <ParametersForm
          country={country}
          touchpoints={touchpoints}
          onSubmit={(values) =>
            handleSubmitParametersCampaign(
              values,
              country,
              calculateCampaignParameters
            )
          }
        />
        {isFetchingData ? (
          <LoaderWrapper>
            <CircularProgress />
          </LoaderWrapper>
        ) : (
          <>
            {!isEmpty(estimatedBudget) && (
              <EstimatedBudgetsTable
                country={country}
                columns={estimatedBudgetTableColumns}
                rows={estimatedBudget}
                currency="EUR"
                exchangeRate={exchangeRateResponse?.data.exchange_rate_eurpln}
              />
            )}
            {!isEmpty(parametersData) && (
              <ParametersTable
                parameters={translatedParameters}
                touchpoints={touchpoints}
                data={parametersData}
              />
            )}
          </>
        )}
      </ChildrenWrapper>
    </CountryAccordion>
  );
}

export default CountrySection;
