import { TableHead, TableRow, Typography } from '@mui/material';
import { TFunction } from 'i18next';
import { COMMENT_PADDING, PARAMETER_PADDING } from '../constants';
import { StyledHeaderCell } from '../styles';
import { TableProps } from '../types';

export const renderTableHead = (
  touchpoints: TableProps['touchpoints'],
  t: TFunction<'translation', undefined, 'translation'>
) => {
  return (
    <TableHead>
      <TableRow>
        <StyledHeaderCell
          sx={{ width: '20rem', padding: PARAMETER_PADDING }}
          align="left"
        >
          <Typography
            color="textDark.main"
            variant="caption"
            textTransform="uppercase"
            fontWeight={600}
          >
            {t('parameter')}
          </Typography>
        </StyledHeaderCell>
        {touchpoints.map((touchpoint) => (
          <StyledHeaderCell key={touchpoint} align="center">
            <Typography
              color="textDark.main"
              variant="caption"
              textTransform="uppercase"
              fontWeight={600}
            >
              {touchpoint}
            </Typography>
          </StyledHeaderCell>
        ))}
        <StyledHeaderCell
          sx={{ width: '20rem', padding: COMMENT_PADDING }}
          align="left"
        >
          <Typography
            color="textDark.main"
            variant="caption"
            textTransform="uppercase"
            fontWeight={600}
          >
            {t('comment')}
          </Typography>
        </StyledHeaderCell>
      </TableRow>
    </TableHead>
  );
};
