import { CSSProperties } from 'react';
import { TableProps } from 'shared/components/EstimatedBudgetsTable/types';
import BudgetTypography from '../components/BudgetTypography/BudgetTypography';

export const renderLabelCell = (
  key: string,
  rowColor: string,
  style: CSSProperties,
  t: (key: string) => string,
  exchangeRate?: TableProps['exchangeRate']
): JSX.Element => {
  if (key === 'budgetMustHave') {
    return (
      <td style={{ ...style, height: exchangeRate ? '2.5rem' : '1.5rem' }}>
        <BudgetTypography color={rowColor} value={t(key)} />

        {exchangeRate && (
          <BudgetTypography
            color={rowColor}
            value={`Ex. Rate L'oreal: ${exchangeRate}`}
          />
        )}
      </td>
    );
  }
  return (
    <td>
      <BudgetTypography color={rowColor} value={t(key)} style={style} />
    </td>
  );
};
