import { chunk as _chunk, map, reverse } from 'lodash';
import { TFunction } from 'i18next';
import { EMPTY_VALUE, PERIODS } from './constants';
import { Currencies, Row } from './types';

export const formatNumber = (num: string | number | null): string => {
  if (!num) return EMPTY_VALUE;
  const chunks = _chunk(num.toString().split('').reverse(), 3);
  const formattedChunks = map(chunks, (chunk: string[]) =>
    reverse(chunk).join('')
  );
  return reverse(formattedChunks).join(' ');
};

export const formatCurrency = (
  num?: number | null,
  currency: Currencies = 'PLN'
): string => {
  if (!num) return EMPTY_VALUE;
  const formattedNumber = formatNumber(num);
  return `${formattedNumber} ${currency}`;
};

export const getNextPeriod = (currentPeriod: string): string => {
  const currentPeriodIndex = PERIODS.findIndex(
    (month) => month === currentPeriod
  );

  if (currentPeriodIndex === -1 || currentPeriodIndex === 13) {
    return PERIODS[0];
  }
  return PERIODS[currentPeriodIndex + 1];
};

export const getRowIdsByPriority = (
  rows: Row[],
  isMustHave: boolean,
  checked = false
) =>
  rows.reduce((acc, item) => {
    if (item.isMustHave === isMustHave) {
      return { ...acc, [item.id]: checked };
    }
    return acc;
  }, {} as Record<number, boolean>);

export const getComment = (
  touchpoints: unknown[],
  t: TFunction<'translation', undefined, 'translation'>
) =>
  touchpoints.some((c) => typeof c === 'boolean' && !c)
    ? t('calculator.comment')
    : null;
