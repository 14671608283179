import { CircularProgress, Stack, styled } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import { shallow } from 'zustand/shallow';
import ParametersTable from 'shared/components/ParametersTable/ParametersTable';
import { Countries } from 'shared/enums';
import useEstimatedBudgetColumns from 'shared/hooks/useEstimatedBudgetColumns';
import TotalBudgetWidget from 'shared/components/TotalBudgetWidget/TotalBudgetWidget';
import { useTotalBudgetStore } from 'store/totalBudgetStore';
import {
  ParametersFormData,
  useCountriesDataStore,
} from 'store/countriesDataStore';
import ParametersForm from 'shared/components/ParametersForm/ParametersForm';
import EstimatedBudgetsTable from 'shared/components/EstimatedBudgetsTable/EstimatedBudgetsTable';
import { useMutation } from 'react-query';
import { calculateCampaignParameters } from 'api/services/campaigns';
import { Moment } from 'moment';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { DATE_FORMATS, parametersPoland } from 'shared/constants';
import { LoaderWrapper } from 'shared/styles';
import useTranslatedParameters from 'shared/hooks/useTranslatedParameters';

const Wrapper = styled(Stack)({
  flexGrow: 1,
  margin: 0,
  padding: '2rem 0',
  gap: '2rem',
});

const touchpoints = ['youtube', 'meta', 'tiktok'];

function Poland() {
  const totalBudget = useTotalBudgetStore((state) => state, shallow);
  const translatedParametrs = useTranslatedParameters(parametersPoland);

  const {
    data,
    mutate: calculateCampaignParametersMutation,
    isLoading,
  } = useMutation(calculateCampaignParameters);

  const { estimatedBudget, parameters: parametersCampaign } =
    useCountriesDataStore((state) => state[Countries.POLAND], shallow);

  const { updateBudgetEstimates, updateParameters } = useCountriesDataStore(
    (state) => ({
      updateBudgetEstimates: state[Countries.POLAND].updateEstimatedBudget,
      updateParameters: state[Countries.POLAND].updateParameters,
    }),
    shallow
  );

  useEffect(() => {
    if (!data) return;
    updateBudgetEstimates(data?.data.estimatedBudgets);
    updateParameters(data?.data.parametersCampaign);
  }, [data, updateBudgetEstimates, updateParameters]);

  const estimatedBudgetTableColumns = useEstimatedBudgetColumns();

  const handleSubmitParametersCampaign = (values: FieldValues) => {
    calculateCampaignParametersMutation({
      body: {
        ...(values as ParametersFormData),
        campaignBumper: values.campaignBumper / 100,
        campaignDuration: Number(values.campaignDuration),
        campaignStartDate: (values.campaignStartDate as Moment).format(
          DATE_FORMATS.BY_HYPHEN
        ),
      },
      countryISOCode: Countries.POLAND,
    });
  };

  return (
    <Wrapper>
      <TotalBudgetWidget data={totalBudget} />
      <ParametersForm
        country={Countries.POLAND}
        onSubmit={handleSubmitParametersCampaign}
      />
      {isLoading ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : (
        <>
          {!isEmpty(estimatedBudget) && (
            <EstimatedBudgetsTable
              columns={estimatedBudgetTableColumns}
              rows={estimatedBudget}
            />
          )}
          {!isEmpty(parametersCampaign) && (
            <ParametersTable
              parameters={translatedParametrs}
              touchpoints={touchpoints}
              data={parametersCampaign}
            />
          )}
        </>
      )}
    </Wrapper>
  );
}

export default Poland;
