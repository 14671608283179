import { Countries } from 'shared/enums';
import { fetchData, postData } from '../utils';
import { ApiResponse } from '../types';

export const CAMPAIGNS_KEY = 'campaign';

export const getCampaignTypes = async (
  divisionName: string,
  countryISOCode = Countries.POLAND
) => {
  return fetchData<
    ApiResponse<{
      campaignTypes: string[];
    }>
  >(`/${CAMPAIGNS_KEY}/campaign-types`, {
    params: { countryISOCode, divisionName },
  });
};

export const getTouchpoints = async (
  divisionName: string,
  campaignTypeName: string,
  countryISOCode = Countries.POLAND
) => {
  return fetchData<
    ApiResponse<{
      youtube: string[];
      meta: string[];
      tiktok: string[];
    }>
  >(`/${CAMPAIGNS_KEY}/touchpoints`, {
    params: { countryISOCode, divisionName, campaignTypeName },
  });
};

export const calculateCampaignParameters = async ({
  body,
  countryISOCode = Countries.POLAND,
}: {
  body: {
    divisionName: string;
    campaignTypeName: string;
    campaignStartDate: string;
    campaignDuration: number | null;
    budget?: number;
    youtubeTargetGroup?: string;
    facebookTargetGroup?: string;
    tiktokTargetGroup?: string;
    campaignBumper: number;
  };
  countryISOCode?: Countries;
}) => {
  return postData<
    ApiResponse<{
      estimatedBudgets: {
        id: string;
        touchpointName: string;
        touchpointFunnel: string;
        isMustHave: boolean;
        techCost: number;
        budget: number;
        impressions: number;
        reach: number;
        clicks: number;
        videoViews: number;
      }[];
      parametersCampaign: {
        id: string;
        youtube: string;
        facebook: string;
        tiktok: string;
      }[];
    }>
  >(`/${CAMPAIGNS_KEY}/campaign-parameters`, body, {
    params: { countryISOCode },
  });
};

export const getCampaignAttributes = async (
  divisionName: string,
  campaignTypeName: string,
  countryISOCode = Countries.POLAND
) => {
  return fetchData<
    ApiResponse<{
      campaignAttributes: {
        campaignDurationMin: number;
        campaignDurationMax: number;
        bumperRecommended: number;
        bumperMin: number;
        bumperMax: number;
      };
    }>
  >(`/${CAMPAIGNS_KEY}/campaign-attributes`, {
    params: { countryISOCode, divisionName, campaignTypeName },
  });
};
