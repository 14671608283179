import axios from 'axios';

let authToken = '';

const setAuthToken = (token: string) => {
  authToken = token;
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (authToken) {
      const newConfig = { ...config };
      newConfig.headers.Authorization = `Bearer ${authToken}`;
      return newConfig;
    }
    return Promise.reject(new Error('No token set'));
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { setAuthToken };
export default axiosInstance;
