import { Checkbox, Grid, Stack, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import Header from 'shared/components/Header/Header';
import { useTranslation } from 'react-i18next';
import { HeaderWrapper, Label, PeriodHeader, Row } from '../styles';
import PeriodRow from './PeriodRow/PeriodRow';

function Step4() {
  const { t } = useTranslation();
  const { control, setValue, getValues } = useFormContext();

  const values = getValues();

  const handleChange = (event: any, name: string) => {
    setValue(name, event.target.value);
  };

  return (
    <Grid container item rowGap="2rem" style={{ marginTop: '3rem' }}>
      <HeaderWrapper>
        <Header title={t('calculator.tvValueCalculator')} />
      </HeaderWrapper>
      <Grid container item sx={{ gap: '0.3rem' }}>
        <PeriodHeader variant="subtitle2" sx={{ height: '2rem' }}>
          {t('period', { number: 1 })}
        </PeriodHeader>
        <Row container item>
          <PeriodRow period="period1" onChange={handleChange} />
        </Row>
        <Stack direction="row">
          <PeriodHeader variant="subtitle2">
            {t('period', { number: 2 })}
          </PeriodHeader>
          <Checkbox
            checked={values.shouldIncludeSecondPeriod}
            onChange={(e) =>
              setValue('shouldIncludeSecondPeriod', e.target.checked)
            }
          />
        </Stack>

        <Row container item>
          <PeriodRow period="period2" onChange={handleChange} />
        </Row>
      </Grid>
      <Label>{t('calculator.campaignValue')}</Label>
      <Stack direction="row" gap="2rem">
        <Controller
          name="campaignValue"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              type="number"
              label={t('calculator.campaignValue')}
              {...field}
              error={!!error}
              onChange={(event) =>
                setValue('campaignValue', event.target.value)
              }
            />
          )}
        />
      </Stack>
    </Grid>
  );
}

export default Step4;
