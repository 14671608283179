import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default (parameters: string[]) => {
  const { t } = useTranslation();
  return useMemo(
    () =>
      parameters.map((parameter) => t(`calculator.parameters.${parameter}`)),
    [parameters, t]
  );
};
