import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ResetStepperFormDialog from 'shared/components/ResetStepperFormModal/ResetStepperFormDialog';
import { useState } from 'react';
import { StyledTooltip } from '../styles';

function Step3({ changeStep }: { changeStep: any }) {
  const { control, setValue, getValues } = useFormContext();
  const [resetDialog, setResetDialog] = useState<{
    show: boolean;
    text?: string;
  }>({
    show: false,
  });
  const { t } = useTranslation();

  return (
    <Grid container item sx={{ gap: '1rem' }} style={{ marginTop: '2rem' }}>
      {resetDialog.show && (
        <ResetStepperFormDialog
          open
          onClose={() => {
            setResetDialog({ show: false });
          }}
          onSuccess={() => {
            setValue('acceptReceivingFullSalesFromThePartner', 'false');
            setValue('dashboardExcel', '');
            setValue('periods', []);
            setValue('campaignValue', 0);
            setValue('step', 2);
            changeStep(2);
          }}
          text={resetDialog.text}
        />
      )}
      <Grid item xs={12}>
        <Box display="flex">
          <Typography>
            {t('digitalFairValue.acceptReceivingFullSalesFromThePartner')}
          </Typography>
          <StyledTooltip
            title={t(
              'digitalFairValue.acceptReceivingFullSalesFromThePartnerTooltip'
            )}
          >
            <InfoOutlinedIcon color="action" />
          </StyledTooltip>
        </Box>
      </Grid>
      <Grid item xs={2.6} pl={4}>
        <Controller
          name="acceptReceivingFullSalesFromThePartner"
          control={control}
          render={({ field }) => (
            <RadioGroup
              {...field}
              row
              aria-labelledby="acceptReceivingFullSalesFromThePartner-radio-buttons-group-label"
              style={{ justifyContent: 'space-between' }}
              onChange={(e, value) => {
                const values = getValues();
                const valuesAfterFilled = values.dashboardExcel !== '';
                if (value === 'false' && valuesAfterFilled) {
                  setResetDialog({
                    show: true,
                    text: 'This will permanently clear all the data you inserted after this point in the form.',
                  });
                } else {
                  field.onChange(value);
                  setValue(field.name, value);
                }
              }}
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                label={t('yes')}
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label={t('no')}
              />
            </RadioGroup>
          )}
        />
      </Grid>
      <Grid item xs={12} style={{ marginTop: '1rem' }}>
        <Box display="flex">
          <Controller
            name="dashboardExcel" // The name attribute for your field
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t('digitalFairValue.dashboardExcel')}
                error={!!error}
                helperText={error?.message || 'Link / Mail'}
                id="dashboardExcel"
                sx={{ width: '34ch' }}
              />
            )}
          />
          <StyledTooltip title={t('digitalFairValue.dashboardExcelTooltip')}>
            <InfoOutlinedIcon color="action" />
          </StyledTooltip>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Step3;
