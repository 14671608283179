import { Countries } from 'shared/enums';
import { fetchData } from '../utils';
import { ApiResponse } from '../types';

export const DIVISIONS_KEY = 'divisions';

export const getDivisions = async (countryISOCode = Countries.POLAND) => {
  return fetchData<
    ApiResponse<{
      divisions: string[];
    }>
  >(`/${DIVISIONS_KEY}`, { params: { countryISOCode } });
};
