import { TFunction } from 'i18next';
import { omit, sumBy } from 'lodash';
import { Countries } from 'shared/enums';
import { formatCurrency, formatNumber } from 'shared/utils';
import XLSX from 'sheetjs-style';
import { CountryData, ParametersFormData } from 'store/countriesDataStore';
import { EMPTY_VALUE } from 'shared/constants';
import { footerBudgetStyles, footerStyles, headerStyles } from './constants';
import getBudgetRow from './getBudgetRow';
import generateData from './generateData';

export default (
  countryData: CountryData,
  formData: ParametersFormData,
  t: TFunction<'translation', undefined, 'translation'>,
  country: Countries
) => {
  const filteredData = countryData.estimatedBudget.filter(
    (x) =>
      (Object.entries(countryData.optionalIds)
        .filter(([_, v]) => v)
        .map(([k, _]) => k)
        .includes(x.id) &&
        !x.isMustHave) ||
      x.isMustHave
  );

  const firstWhitespacesCount = 3;
  const secondWhitespacesCount = 9;

  const ws = generateData(
    countryData,
    filteredData,
    formData,
    country,
    firstWhitespacesCount,
    secondWhitespacesCount,
    t
  );

  const mediaColumns = ['impressions', 'reach', 'clicks', 'videoViews'];
  const footerStart = filteredData.length + 2 + 2 + firstWhitespacesCount;

  const budgetMustHave = getBudgetRow(filteredData, true, mediaColumns, t);
  const budgetOptional = getBudgetRow(filteredData, false, mediaColumns, t);
  const budgetTotal = [
    t('totalMedia'),
    formatCurrency(sumBy(filteredData, 'budget'), 'PLN'),
    formatNumber(sumBy(filteredData, 'impressions')),
    EMPTY_VALUE,
    formatNumber(sumBy(filteredData, 'clicks')),
    formatNumber(sumBy(filteredData, 'videoViews')),
  ];
  const budgetEstTechCosts = [
    t('estimatedCosts'),
    formatCurrency(sumBy(filteredData, 'techCost'), 'PLN'),
    EMPTY_VALUE,
    EMPTY_VALUE,
    EMPTY_VALUE,
    EMPTY_VALUE,
  ];

  XLSX.utils.sheet_add_aoa(
    ws,
    [budgetMustHave, budgetOptional, budgetTotal, budgetEstTechCosts],
    {
      origin: `C${footerStart}`,
    }
  );

  ws['!cols'] = [
    { wpx: 300 }, // Touchpoint
    { wpx: 200 }, // Touchpoint Funnel
    { wpx: 200 }, // Is Must Have
    { wpx: 200 }, // Budget
    { wpx: 200 }, // Impressions
    { wpx: 100 }, // Reach
    { wpx: 100 }, // Clicks
    { wpx: 100 }, // Video Views
  ];

  // footer - budget column styles
  ws[`D${footerStart}`].s = footerBudgetStyles;
  ws[`D${footerStart + 1}`].s = footerBudgetStyles;
  ws[`D${footerStart + 2}`].s = footerBudgetStyles;
  ws[`D${footerStart + 3}`].s = footerBudgetStyles;

  // footer - first row styles
  const estimatedBudgetColumnLength =
    Object.keys(omit(filteredData[0], ['id', 'techCost'])).length - 1;
  for (let i = 0; i <= estimatedBudgetColumnLength; i++) {
    const column = String.fromCharCode(65 + i);

    if (column === 'D') {
      ws[`${column}${footerStart - 1}`].s = {
        border: { ...footerStyles.border, ...footerBudgetStyles.border },
      };
    } else if (column === 'C') {
      ws[`${column}${footerStart - 1}`].s = {
        ...footerStyles,
        font: {
          color: ws[`C${footerStart - 1}`].v.includes('Optional')
            ? { rgb: '2F80ED' }
            : { rgb: '8043B9' },
        },
      };
    } else {
      ws[`${column}${footerStart - 1}`].s = footerStyles;
    }
  }

  const styleFooterLabelAndBudget = (color: string, index: number) => {
    ws[`C${footerStart + index}`].s = { font: { color: { rgb: color } } };
    ws[`D${footerStart + index}`].s = {
      font: { color: { rgb: color } },
      ...footerBudgetStyles,
    };
  };

  styleFooterLabelAndBudget('8043B9', 0);
  styleFooterLabelAndBudget('2F80ED', 1);
  styleFooterLabelAndBudget('3B455E', 3);

  // style parameters table
  ws[`A${footerStart + secondWhitespacesCount}`].s = headerStyles;
  ws[`B${footerStart + secondWhitespacesCount}`].s = headerStyles;
  ws[`C${footerStart + secondWhitespacesCount}`].s = headerStyles;
  ws[`D${footerStart + secondWhitespacesCount}`].s = headerStyles;
  ws[`E${footerStart + secondWhitespacesCount}`].s = headerStyles;

  return ws;
};
