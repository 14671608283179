import { useEffect, useState } from 'react';
import { Countries } from 'shared/enums';
import { getRowIdsByPriority } from 'shared/utils';
import { useTotalBudgetStore } from 'store/totalBudgetStore';
import { shallow } from 'zustand/shallow';
import { defaultData, footerRow } from '../constants';
import { FooterData, UseFooterDataProps } from '../types';
import {
  calculateMetrics,
  calculateMustHaveTotal,
  calculateOptional,
} from '../utils';

const useFooterData = ({
  rows,
  optionalIds,
  country = Countries.POLAND,
  currency = 'PLN',
}: UseFooterDataProps) => {
  const { updateBudget } = useTotalBudgetStore(
    (state) => ({ updateBudget: state.updateBudget }),
    shallow
  );

  const [footerData, setFooterData] = useState<FooterData>(defaultData);

  useEffect(() => {
    const mustHaveIds = getRowIdsByPriority(rows, true);
    const techCosts = rows
      .map(({ techCost }) => techCost)
      .filter(Boolean)
      .reduce((acc, curr) => acc + curr, 0);

    const newFooterData = {
      budgetMustHave: calculateMetrics((metric) =>
        calculateMustHaveTotal(metric, rows, mustHaveIds)
      ),
      optionalTouchpoints: calculateMetrics((metric) =>
        calculateOptional(metric, rows, optionalIds)
      ),
      totalMedia: calculateMetrics(
        (metric) =>
          calculateMustHaveTotal(metric, rows, mustHaveIds) +
          calculateOptional(metric, rows, optionalIds)
      ),
      estimatedCosts: {
        ...footerRow,
        budget: techCosts,
      },
    };

    updateBudget(country, {
      mustHave: newFooterData.budgetMustHave.budget,
      optional: newFooterData.optionalTouchpoints.budget,
      total: newFooterData.totalMedia.budget,
    });

    setFooterData(newFooterData);
  }, [rows, optionalIds, currency, updateBudget, country]);
  return footerData;
};

export default useFooterData;
