import { TFunction } from 'i18next';
import { isEmpty } from 'lodash';
import moment from 'moment';
import XLSX from 'sheetjs-style';
import { Countries } from 'shared/enums';
import { StoreCountriesData } from 'store/countriesDataStore';
import generateWorkbook from './generateWorkbook';

export default (
  countriesData: StoreCountriesData,
  formData: StoreCountriesData,
  t: TFunction<'translation', undefined, 'translation'>
) => {
  const workbook = XLSX.utils.book_new();
  const countries = [
    {
      code: Countries.POLAND,
      name: 'Poland',
      data: countriesData.PL,
      formData: formData.PL.formData,
    },
    {
      code: Countries.LITHUANIA,
      name: 'Lithuania',
      data: countriesData.LT,
      formData: formData.LT.formData,
    },
    {
      code: Countries.LATVIA,
      name: 'Latvia',
      data: countriesData.LV,
      formData: formData.LV.formData,
    },
    {
      code: Countries.ESTONIA,
      name: 'Estonia',
      data: countriesData.EE,
      formData: formData.EE.formData,
    },
  ];

  countries.forEach((country) => {
    const { code, name, data, formData: countryFormData } = country;
    if (!isEmpty(data.estimatedBudget) && !isEmpty(data.parameters)) {
      const worksheet = generateWorkbook(data, countryFormData, t, code);
      XLSX.utils.book_append_sheet(workbook, worksheet, name);
    }
  });

  if (workbook.SheetNames.length === 0) {
    XLSX.utils.book_append_sheet(workbook, XLSX.utils.aoa_to_sheet([]), '');
  }

  XLSX.writeFile(workbook, `media-calculations-${moment().unix()}.xlsx`);
};
