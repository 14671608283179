import { TFunction } from 'i18next';
import * as yup from 'yup';

export function buildYupLocale(
  _: unknown,
  t: TFunction<'translation', undefined, 'translation'>
): void {
  yup.setLocale({
    mixed: {
      required: t('validation.required')!,
    },
  });
}
